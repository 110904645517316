import { VDirective, VServices } from '@libTs/vue-base';
import { OverlaysService } from '../services/overlays.service';

const SERVICES = VServices( { overlays: OverlaysService } );

export const LangSwitcherDirective: VDirective = [
  'langSwitcher',
  {
    bind( el: HTMLElement ): void {
      $( el ).on( 'click', () => {
        // hide all overlays
        // SERVICES.overlays.hide();

        const angle = $( el ).find( 'i' );

        if ( angle.hasClass( 'fa-angle-down' ) ) {
          angle.removeClass( 'fa-angle-down' );
          angle.addClass( 'fa-angle-up' );
        } else {
          angle.addClass( 'fa-angle-down' );
          angle.removeClass( 'fa-angle-up' );
        }

        const elToToggle = $( el ).next( 'ul' );
        if ( elToToggle[ 0 ] ) {
          elToToggle.toggleClass( 'hidden' );
        }
      } );
    },
  },
];
