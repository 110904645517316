





import Vue from "vue";

export default Vue.extend({
  name: 'ScrollTop',
  data() {
    return {
      isShown: false,
      scrollTimer: null,
      isScrolling: false
    }
  },
  created () {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll (e: any) {
      if(this.scrollTimer != null) {
        clearTimeout(this.scrollTimer);
      }
      // this.isShown = false;

      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0;

      this.scrollTimer = setTimeout(() => {
        this.isShown = top > window.innerHeight / 2;
      }, 100);
    },
    toTop () {
      this.isScrolling = true;
      let scrollStart = window.pageYOffset;
      let scrollSpeed = 2;

      let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
            window.scrollTo(0, pos - scrollSpeed); // how far to scroll on each step

            if(pos >= scrollStart * 0.666) {
                scrollSpeed *= 1.08;
            }
            else if(pos <= scrollStart * 0.333) {
              scrollSpeed /= 1.09;
            }

            if(scrollSpeed <= 1) {
              scrollSpeed = 1;
            }
          } else {
              window.clearInterval(scrollToTop);

              setTimeout(() => {
                this.isScrolling = false
              }, 400)
          }
      }, 16);
    }
  }
});
