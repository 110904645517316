var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pt-8 pb-20",
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.search(_vm.searchTerm, $event)
        }
      }
    },
    [
      _c(
        "div",
        {
          class:
            _vm.autoCompleteItems.length && _vm.searchTerm
              ? "search-shadow-container"
              : "search-standby-container"
        },
        [
          _c("div", { staticClass: "search-container" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm"
                }
              ],
              staticClass: "search-input outline-none",
              attrs: {
                type: "text",
                autocomplete: "off",
                name: "q",
                placeholder: _vm.t("Suche")
              },
              domProps: { value: _vm.searchTerm },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchTerm = $event.target.value
                  },
                  function($event) {
                    return _vm.autoComplete()
                  }
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-search",
                on: {
                  click: function($event) {
                    return _vm.search(_vm.searchTerm, $event)
                  }
                }
              },
              [_c("i", { staticClass: "far fa-search" })]
            )
          ]),
          _vm._v(" "),
          _vm.autoCompleteItems.length && _vm.searchTerm
            ? _c(
                "div",
                { staticClass: "autocomplete-container bg-color1 rounded-lg" },
                _vm._l(_vm.autoCompleteItems, function(item, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      staticClass: "search-autocomplete-item",
                      attrs: { href: item.url }
                    },
                    [
                      _c("div", { staticClass: "inline-block align-middle" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "fas fa-angle-right ml-auto" })
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }