var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.itemsShowing && _vm.itemsShowing.length > 0
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "filter-container flex gap-2 mb-4" },
            _vm._l(_vm.types, function(type, index) {
              return _c(
                "button",
                {
                  key: index,
                  staticClass: "btn-small",
                  class: { "btn-filled-small": _vm.filters.includes(type) },
                  on: {
                    click: function($event) {
                      return _vm.toggleFilter(type)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t(type)) +
                      " (" +
                      _vm._s(_vm.getNumberOfType(type)) +
                      ")\n    "
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "TransitionGroup",
            {
              staticClass:
                "grid grid-cols-4 gap-4 l:grid-cols-3 m:grid-cols-2 s:grid-cols-1",
              attrs: { name: "list", tag: "div" }
            },
            _vm._l(_vm.itemsShowing.slice(0, _vm.show), function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "overflow-hidden card-item",
                  style: {
                    "transition-delay": (index - _vm.itemsBefore) * 100 + "ms"
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "pt-3/4 block relative bg-center bg-cover api-block",
                      attrs: {
                        href: _vm.url.getDetailUrl(
                          item.title.replace("'", ""),
                          item.type,
                          item.id
                        )
                      }
                    },
                    [
                      _c("div", { staticClass: "card-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cuprum-f2 uppercase break-word-hyphen"
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "nib-f2" }, [
                          _vm._v(_vm._s(_vm.t(item.type)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "slide-filter" }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "slide-background",
                        style:
                          "background-image:url(http://img.ecmaps.de/remote/.jpg?width=1000&url=" +
                          (_vm.getFirstImage(item)
                            ? _vm.getFirstImage(item).url
                            : _vm.baseUrl +
                              "/assets/default" +
                              item.type +
                              ".jpg") +
                          ")"
                      })
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.show < _vm.itemsShowing.length
            ? _c(
                "div",
                {
                  staticClass: "text-center mt-8",
                  on: {
                    click: function($event) {
                      _vm.itemsBefore = _vm.show
                      _vm.show += 8
                    }
                  }
                },
                [
                  _c("button", { staticClass: "btn" }, [
                    _vm._v(_vm._s(_vm.t("Mehr laden")))
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }