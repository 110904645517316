var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-container" }, [
    _c("div", { staticClass: "row-container" }, [
      _c(
        "div",
        { staticClass: "row-list" },
        _vm._l(_vm.data, function(item, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("div", {
              staticClass: "list-image",
              style: "background-image:url(" + item.imageUrl + ")"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "list-content" }, [
              _c("div", { staticClass: "cuprum-f3" }, [
                _vm._v(_vm._s(item.titel))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "nib-f3" }, [
                _vm._v(_vm._s(item.spotType))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4" }, [_vm._v(_vm._s(item.text))]),
              _vm._v(" "),
              item.spotLink
                ? _c(
                    "a",
                    {
                      staticClass: "map-button",
                      attrs: { href: item.spotLink }
                    },
                    [
                      _vm._v("Link "),
                      _c("i", { staticClass: "fa fa-arrow-right font-light" })
                    ]
                  )
                : _vm._e()
            ])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row-map" },
        [
          _c("div", { staticClass: "mapContainer", attrs: { id: "cms-map" } }),
          _vm._v(" "),
          _c("div", { attrs: { id: "popup-container" } }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.data, function(item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activePopup == index,
                    expression: "activePopup == index"
                  }
                ],
                key: index,
                attrs: { id: "content" + index }
              },
              [
                _c("div", {
                  staticClass: "bubble-image",
                  style: "background-image:url(" + item.imageUrl + ")"
                }),
                _vm._v(" "),
                _c("div", { staticClass: "bubble-content" }, [
                  _c("div", { staticClass: "cuprum-f3" }, [
                    _vm._v(_vm._s(item.titel))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "nib-f3" }, [
                    _vm._v(_vm._s(item.spotType))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4 text-f5 popup-text" }, [
                    _vm._v(_vm._s(item.text))
                  ]),
                  _vm._v(" "),
                  item.spotLink
                    ? _c(
                        "a",
                        {
                          staticClass: "text-f5 map-button",
                          attrs: { href: item.spotLink }
                        },
                        [
                          _vm._v("Link "),
                          _c("i", {
                            staticClass: "fa fa-arrow-right font-light"
                          })
                        ]
                      )
                    : _vm._e()
                ])
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "close-icon", attrs: { id: "popup-closer" } },
      [_c("i", { staticClass: "fas fa-times" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }