var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "scroll-top-icon",
    class: { showing: _vm.isShown, "scrolling-up": _vm.isScrolling },
    on: {
      click: function($event) {
        return _vm.toTop()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }