<template>
  <div class="map-container">
    <div class="row-container">
      <div class="row-list">
        <div v-for="(item, index) in data.items" class="item" :key="index">
          <div class="list-image" v-bind:style="'background-image:url(http://img.ecmaps.de/remote/.jpg?width=600&url=' + (getFirstImage(item) ? getFirstImage(item).url : '') + ')'"></div>
          <div class="list-content">
            <div class="cuprum-f3">{{ item.title }}</div>
            <div class="nib-f3">{{ item.type }}</div>
            <div class="mb-4">{{ getText(item, 'teaser') }}</div>
            <a class="map-button" :href="url.getDetailUrl(item.title.replace('\'', ''), item.type, item.id)">Link <i class="fa fa-arrow-right font-light"></i></a>
          </div>
        </div>
      </div>
      <div class="row-map">
        <div id="map" class="mapContainer"></div>
        <div v-for="(item, index) in data.items" :id="'content' + index" v-show="activePopup == index" :key="index">
          <div class="bubble-image" v-bind:style="'background-image:url(http://img.ecmaps.de/remote/.jpg?width=600&url=' + (getFirstImage(item) ? getFirstImage(item).url : '') + ')'"></div>
          <div class="bubble-content">
            <div class="cuprum-f3">{{ item.title }}</div>
            <div class="nib-f3">{{ item.type }}</div>
            <div class="mb-4 text-f5 popup-text">{{ getText(item, 'teaser') }}</div>
            <a class="text-f5 map-button" :href="url.getDetailUrl(item.title.replace('\'', ''), item.type, item.id)">Link <i class="fa fa-arrow-right font-light"></i></a>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { VServices } from '@libTs/vue-base';
import Vue from "vue";
import Popup from '../popup.ts';
import { apiService } from '../services/api.service';
import { urlService } from '../services/url.service';
import vueSlickCarousel from 'vue-slick-carousel';

const SERVICES = VServices({ api: apiService, url: urlService });

export default Vue.extend({
  name: "CustomMap",
  props: {
    config: Object,
  },
  components: {
    vueSlickCarousel,
  },
  data: function () {
    return {
        myLatLng: { lat: 46.93038961832949, lng: 8.597718768318275 },
        map: null,
        activePopup: null,
        data: null,
        url: SERVICES.url,
    };
  },
  methods: {
    getFirstImage(item) {
      return item.media_objects.filter((mediaObject) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },    
    getText(item, type) {
      let textsOfType = item.texts.filter((text) => text.rel == type && text.type == "text/plain");

      if(textsOfType.length <= 0) {

        textsOfType = item.texts.filter((text) => text.rel == type);
      }

      if(textsOfType.length > 0) {
        return textsOfType[0].value;
      }
      else {
        return '';
      }
    },
  },
  mounted() {
    SERVICES.api.getData(this.config).then((response) => {
      this.data = response.data;


      this.$nextTick(() => {
        let map = new window.google.maps.Map(document.getElementById('map'), {
          center: this.myLatLng,
          zoom: 12,
          mapId: 'f7dd94523995fb93'
        })


        this.data.items.forEach((item, index) => {
          let latitude = item.geo.main.latitude;
          let longitude = item.geo.main.longitude;

          let itemLatLng = { lat: latitude, lng: longitude };

          let popup = new Popup(
            new window.google.maps.LatLng(latitude + 0.008, longitude),
            document.getElementById("content" + index)
          );
          popup.setMap(map);

          let marker = new window.google.maps.Marker({
            position: itemLatLng,
            map,
            title: item.title,
            icon: {
              url: "https://wiegederschweiz.binaryone.ch/assets/marker.png",
              scale: 0.5,
            }
          });

          marker.addListener("click", () => {
            if(this.activePopup == index) {
              this.activePopup = null;
            }
            else {
              this.activePopup = index;
            }
          });
        });

      });
    });

    
  },
});
</script>
