import { render, staticRenderFns } from "./map-multiple.vue?vue&type=template&id=16e20fc3&"
import script from "./map-multiple.vue?vue&type=script&lang=js&"
export * from "./map-multiple.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/binaryone/wiege-der-schweiz/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16e20fc3')) {
      api.createRecord('16e20fc3', component.options)
    } else {
      api.reload('16e20fc3', component.options)
    }
    module.hot.accept("./map-multiple.vue?vue&type=template&id=16e20fc3&", function () {
      api.rerender('16e20fc3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ts/components/map-multiple.vue"
export default component.exports