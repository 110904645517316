var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "TransitionGroup",
        {
          staticClass:
            "grid grid-cols-4 gap-4 l:grid-cols-3 m:grid-cols-2 s:grid-cols-1",
          attrs: { name: "list", tag: "div" }
        },
        _vm._l(_vm.items.slice(0, _vm.show), function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "overflow-hidden card-item",
              style: { transitionDelay: (index - _vm.itemsBefore) * 100 + "ms" }
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "pt-3/4 block relative bg-center bg-cover api-block",
                  style:
                    "background-image:url(" + _vm.getFirstImage(item).url + ")",
                  attrs: {
                    href: _vm.url.getDetailUrl(
                      item.title.replace("'", ""),
                      item.type,
                      item.id
                    )
                  }
                },
                [
                  _c("div", { staticClass: "card-content" }, [
                    _c(
                      "div",
                      { staticClass: "cuprum-f2 uppercase break-word-hyphen" },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "nib-f2" }, [
                      _vm._v(_vm._s(_vm.t(item.type)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "slide-filter" }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "slide-background",
                    style:
                      "background-image:url(http://img.ecmaps.de/remote/.jpg?width=1000&url=" +
                      (_vm.getFirstImage(item)
                        ? _vm.getFirstImage(item).url
                        : _vm.baseUrl +
                          "/assets/default" +
                          item.type +
                          ".jpg") +
                      ")"
                  })
                ]
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-center mt-8" }, [
        _vm.show < _vm.items.length
          ? _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    _vm.itemsBefore = _vm.show
                    _vm.show += 8
                  }
                }
              },
              [_vm._v(_vm._s(_vm.t("Mehr laden")))]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }