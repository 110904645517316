
















import { BASEURL } from '@libTs/cms.globals';
import axios from 'axios';
import Vue from 'vue';

export default Vue.extend({
  name: 'weather',
  props: {
    city: String,
    kanton: String,
  },
  components: {},
  data() {
    return {
      weather: {
        temp: '',
        icon: '',
      },
    };
  },
  created() {
    const API_KEY = '1296dde342be877e9d8e98aa725ada6e';
    const requestUrl = `https://api.openweathermap.org/data/2.5/weather?q=${this.city},${this.kanton},ch?&appid=${API_KEY}`;

    axios.get(requestUrl).then((r) => {
      const weatherData = r.data.weather[0];
      const tempData = r.data.main;
      this.weather.icon = `${BASEURL}/assets/weather-icons/${weatherData.icon}.png`;
      this.weather.temp = `${Math.round(Number(tempData.temp) - 273.15)}°C`;
    });
  },
});
