/* eslint-disable @typescript-eslint/ban-types */
/**
 *	===============================================================================================
 *		 ____ _____ _   _          _______     __                 _____           _     _    _
 *		|  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *		| |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *		|  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *		| |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *		|____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *	===============================================================================================
 *
 * @author	BINARY one GmbH
 * @license Unlicensed
 */

import { ENV } from '@libTs/env';
import { createVueApp } from '@libTs/vue-base';
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import { BASEURL, SITEURL } from '@libTs/cms.globals';
// import { SliderHelper } from './helpers/slider.helper';

//Services
import { apiService } from "./services/api.service";
import { urlService } from "./services/url.service";
import { SearchService } from "./services/search.service";

//Components
import gridBlock from './components/grid-block.vue';
import sliderApi from './components/slider-api.vue';
import highlightBlock from './components/highlight-block.vue';
import apiBlock from './components/api-block.vue';
import apiFilterBlock from './components/api-filter-block.vue';
import apiEventFilterBlock from './components/api-event-filter-block.vue';
import detailPage from './components/detail-page.vue';
import tourDetail from './components/tour-detail.vue';
import angebotDetail from './components/angebot-detail.vue';
import schulangebotDetail from './components/schulangebot-detail.vue';
import geschichtenDetail from './components/geschichten-detail.vue';
import akkordeon from './components/akkordeon.vue';
import akkordeonSlot from './components/akkordeon-slot.vue';
import customMap from './components/custom-map.vue';
import customSwissMap from './components/custom-swiss-map.vue';
import MapMultiple from './components/map-multiple.vue';
import schulangebotMap from './components/schulangebot-map.vue';
import geschichtenMap from './components/geschichten-map.vue';
import cmsMap from './components/cms-map.vue';
import search from './components/search.vue';
import searchInput from './components/search-input.vue';
import ScrollTop from './components/scroll-top.vue';
import weather from './components/weather.vue';
import webcams from './components/webcams.vue';
import VueSlickCarousel from 'vue-slick-carousel';

import { OverlaysService } from './services/overlays.service';
import { OverlayDirective } from './directives/v-overlay.directive';
import { LangSwitcherDirective } from './directives/v-lang-switcher.directive';

import './animations';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';
import { TranslationService } from './services/translation.service';



// -----------------------------------------------------------
// DEBUG output

if ( ENV.debug ) {
  console.group( 'Environment loaded' );
  console.log( ENV );
  console.log(
    ' -----------------------------------\n',
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    '-----------------------------------'
  );
  console.groupEnd();
}

// -----------------------------------------------------------
// Vue app

const app = createVueApp(
  // ----------------------------
  // App config
  // It is similar but not equal to "new Vue( { ... } );"
  {
    el: '#vue-app',
    services: [
      apiService,
      urlService,
      OverlaysService,
      SearchService,
      TranslationService
    ],
    directives: [
      OverlayDirective,
      LangSwitcherDirective
    ],
    components: {
      gridBlock,
      sliderApi,
      highlightBlock,
      apiBlock,
      apiFilterBlock,
      apiEventFilterBlock,
      detailPage,
      tourDetail,
      angebotDetail,
      schulangebotDetail,
      geschichtenDetail,
      akkordeon,
      akkordeonSlot,
      customMap,
      customSwissMap,
      MapMultiple,
      schulangebotMap,
      geschichtenMap,
      cmsMap,
      search,
      ScrollTop,
      VueSlickCarousel,
      weather,
      webcams,
      searchInput,
    },
    modules: []
  }
);

// Add Axios plugin for AJAX requests
Vue.use( VueAxios, axios );


gsap.registerPlugin(ScrollTrigger);

function reloaded() {
  ScrollTrigger.refresh(true);
}

$(window).on('load', reloaded);

// -----------------------------------------------------------
// Slick Carousel initialization

// const sliderHelper = new SliderHelper( '.slider' );

// sliderHelper.initSliders();

