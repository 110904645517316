import { VBaseService, VServices } from '@libTs/vue-base';
import { CRAFT_TRANSLATIONS, SITEURL } from '@libTs/cms.globals';

export class TranslationService extends VBaseService {

  public t( key: string ): string {
    try {
      return CRAFT_TRANSLATIONS[ key ] ?? key;
    } catch ( error ) {
      return key;
    }
  }
}