




























import Vue, { VNode } from "vue";

export default Vue.extend({
  name: 'Akkordeon',
  props: {
    heading: String,
    text: String,
  },

  data() {
    return {
      show: false,
    };
  },
  updated: function () {
    let height = this.$refs.akkordeonText.clientHeight;
    if (this.show) {
      this.$refs.akkordeonContainer.style.height = height + "px";
    } else {
      this.$refs.akkordeonContainer.style.height = 0;
    }
  },
});
