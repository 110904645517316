import { render, staticRenderFns } from "./api-event-filter-block.vue?vue&type=template&id=2e870a40&"
import script from "./api-event-filter-block.vue?vue&type=script&lang=ts&"
export * from "./api-event-filter-block.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/binaryone/wiege-der-schweiz/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e870a40')) {
      api.createRecord('2e870a40', component.options)
    } else {
      api.reload('2e870a40', component.options)
    }
    module.hot.accept("./api-event-filter-block.vue?vue&type=template&id=2e870a40&", function () {
      api.rerender('2e870a40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ts/components/api-event-filter-block.vue"
export default component.exports