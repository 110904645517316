










































































































































































































































































































































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { apiService } from '../services/api.service';
import vueSlickCarousel from 'vue-slick-carousel';
import { urlService } from '../services/url.service';
import { SITEURL } from '@libTs/cms.globals';
import Akkordeon from './akkordeon.vue';
import AkkordeonSlot from './akkordeon-slot.vue';

import proj4 from "proj4";
import { Map, View, Feature, Overlay } from "ol";
import { Tile as TileLayer, Vector as VectorLayer  } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { XYZ, WMTS } from "ol/source";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import { defaults as defaultsInteractions } from "ol/interaction";
import { register } from "ol/proj/proj4";
import { TileWMS } from "ol/source";
import { Point, MultiLineString, LineString } from "ol/geom";
import { fromLonLat, transform } from "ol/proj";
import { Extent } from "ol/extent";
import { Style, Icon, Fill, Circle, Stroke } from "ol/style";
import TileGrid from "ol/tilegrid/TileGrid";
import { TranslationService } from '../services/translation.service';


const SERVICES = VServices({ api: apiService, url: urlService, translation: TranslationService });

export default Vue.extend({
  name: 'tourDetail',
  props: {
    data: Object
  },
  components: {
    vueSlickCarousel,
    Akkordeon,
    AkkordeonSlot,
  },
  data() {
    return {
      item: this.data,
      url: SERVICES.url,
      etappen: [],
      months: [],
      myLatLng: [8.597718768318275, 46.93038961832949 ],
      id: null,
      offwayTrackpoints: [],
      siteUrl: SITEURL,
      currentUrl: '',
      elevationDisplay: false,
      elevationPosition: 0,
      mapData: null,
      mapObject: null,
      elevationLayer: null,
      elevationSource: null
    };
  },
  created() {
    this.months = [
      this.t('Jan'),
      this.t('Feb'),
      this.t('Mär'),
      this.t('Apr'),
      this.t('Mai'),
      this.t('Jun'),
      this.t('Jul'),
      this.t('Aug'),
      this.t('Sep'),
      this.t('Okt'),
      this.t('Nov'),
      this.t('Dez')
    ];

    this.currentUrl = window.location;
    // const url = window.location.href.replaceAll(SITEURL, '');
    // const urlArray = url.split('/');

    // const type = urlArray[0];
    // const urlSplit = decodeURI(urlArray[1]).split('-');
    // const id = urlSplit[urlSplit.length - 1];
    this.id = this.item.id;

    // const config = {
    //   type: type,
    //   q: 'id:' + id,
    // };

    // SERVICES.api.getDetailData(id).then((response) => {
    //   this.item = response.data.items[0];
      this.getEtappen();

      this.offwayTrackpoints = this.item.subitems.filter((subitem: any) => subitem.type == "OfftrackWaypoint");

      this.offwayTrackpoints.forEach((trackpoint: any) => {

        if(trackpoint.texts.filter((text: any) => text.type == 'Type')[0].value != 'UNDEFINED') {

          SERVICES.api.getDetailData(trackpoint.texts.filter((text: any) => text.type == 'LocalId')[0].value).then((response: any) => {
            trackpoint.details = response.data.items[0];
          })
        }
      });

      setTimeout(() => {
        let links = $('.webkit-content').find('a');
        links.each(index => {
          if(!links[index].href.includes(this.siteUrl)) {
            links[index].target = '_blank';
          }
        });
      }, 10);
      
    // });

    SERVICES.api.getMapData(this.id).then((response) => {
      this.mapData = response;
      
      this.$nextTick(() => {
        // adding Swiss projections to proj4 (proj string comming from https://epsg.io/)
        proj4.defs(
          "EPSG:2056",
          "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs"
        );
        // proj4.defs(
        //   "EPSG:21781",
        //   "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs"
        // );
        register(proj4);


        const layers = [
          {
            url: 'ch.swisstopo.pixelkarte-farbe-pk200.noscale',
            maxZoom: 14,
            minZoom: 1,
          },
          {
            url: 'ch.swisstopo.pixelkarte-farbe',
            maxZoom: 19,
            minZoom: 14,
          }
        ]

        let onlyLayers: any = [];

        layers.forEach((layer: any) => {
          let newlayer = new TileLayer({
            source: new TileWMS({
              url: 'https://wms.geo.admin.ch/',
              crossOrigin: 'anonymous',
              params: {
                'LAYERS': layer.url,
                'FORMAT': 'image/jpeg',
                'TILED': true,
                'VERSION': '1.1.1'
              },
              serverType: 'mapserver',
            }),
            minZoom: layer.minZoom,
            maxZoom: layer.maxZoom,
          });

          onlyLayers.push(newlayer);
        });

        let startZoom = 15;
        let maxZoom = 19;
        let minZoom = 10;

        const view = new View({
          projection: "EPSG:2056",
          center: fromLonLat(response.data.features[0].geometry.coordinates[0], 'EPSG:2056'),
          zoom: startZoom,
          maxZoom: maxZoom,
          minZoom: minZoom,
          extent: [2623219.221963299, 1153464.0175551437, 2749636.822550499, 1250527.8117530728],
        });

        // let startLayer = onlyLayers[0];

        var interactions = defaultsInteractions({altShiftDragRotate:false, pinchRotate:false});


        const map = new Map({
          target: "tour-detail-map",
          controls: defaultControls({rotate: false}).extend([
            new ScaleLine({
              units: "metric"
            })
          ]),
          layers: onlyLayers,
          view: view,
          interactions: interactions
        });

        let southPoint = null;
        let northPoint = null;
        let westPoint = null;
        let eastPoint = null;

        response.data.features.forEach((item: any) => {
          item.geometry.coordinates.forEach((coordinate: any) => {
            if(coordinate[0] < southPoint || southPoint == null) {
              southPoint = coordinate[0];
            }

            if(coordinate[0] > northPoint || northPoint == null) {
              northPoint = coordinate[0];
            }

            if(coordinate[1] < westPoint || westPoint == null) {
              westPoint = coordinate[1];
            }

            if(coordinate[1] > eastPoint || eastPoint == null) {
              eastPoint = coordinate[1];
            }
          });
        })

        southPoint = southPoint - ((northPoint - southPoint) / 10);
        northPoint = northPoint + ((northPoint - southPoint) / 10);
        westPoint = westPoint - ((eastPoint - westPoint) / 10);
        eastPoint = eastPoint + ((eastPoint - westPoint) / 10);

        let fitExtent = [
          fromLonLat([southPoint, westPoint], 'EPSG:2056')[0],
          fromLonLat([southPoint, westPoint], 'EPSG:2056')[1],
          fromLonLat([northPoint, eastPoint], 'EPSG:2056')[0],
          fromLonLat([northPoint, eastPoint], 'EPSG:2056')[1],
        ];

        map.getView().fit(fitExtent);

        let features: Array<any> = [];

        response.data.features.forEach((item: any) => {

          let transformedCoordinates: Array<any> = [];

          item.geometry.coordinates.forEach((coordinate: any) => {
            transformedCoordinates.push(fromLonLat(coordinate, 'EPSG:2056'));
          });

          let markerFeature = new Feature({
            geometry: new LineString(transformedCoordinates)
          });

          const iconStyle = new Style({
            // fill: new Fill({
            //   color: 'rgb(144, 17, 28)'
            // }),
            stroke: new Stroke ({
                color: 'rgba(144,17,28, 0.9)',
                width: 9,
                lineDash: [0.5, 13]
            }),
            image: new Circle ({
                radius: 12,
                fill: new Fill({
                    color: 'rgb(144,17,28)'
                })
            })
          });

          markerFeature.setStyle(iconStyle);

          features.push(
            markerFeature
          );

        });
        
        this.offwayTrackpoints.forEach((item, index) => {
          let latitude = item.geo.main.latitude;
          let longitude = item.geo.main.longitude;

          let itemLatLng = [longitude, latitude];

          let markerFeature = new Feature({
            geometry: new Point(fromLonLat(itemLatLng, 'EPSG:2056')),
            name: item.title,
            id: index
          });
          const iconStyle = new Style({
            image: new Icon({
              anchor: [0.5, 1],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              src: 'assets/location-dot-solid.png',
              scale: 0.07
            }),
          });

          markerFeature.setStyle(iconStyle);

          features.push(
            markerFeature
          );
        });

        var featuresLayer = new VectorLayer({
            source: new VectorSource({
                features: features
            })
        });

        var container = document.getElementById('popup-container');


        map.addLayer(featuresLayer);


        var overlay = new Overlay({
          element: container,
          autoPan: true,
          autoPanAnimation: {
              duration: 250
          }
        });

        map.addOverlay(overlay);


        map.on('click', function (evt) {
          var feature = map.forEachFeatureAtPixel(evt.pixel, function (feat, layer) {
              return feat;
          });

          if (feature) {
              var coordinate = evt.coordinate;

              container.innerHTML = document.getElementById('content' + feature.get('id')).innerHTML;
              overlay.setPosition(coordinate);
          }
          else {
            overlay.setPosition(undefined);
          }
        });

        map.on("pointermove", function (evt) {
          var hit = this.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
            return true;  
          }); 
          if (hit) {
            this.getTargetElement().style.cursor = 'pointer';
          } else {
            this.getTargetElement().style.cursor = '';
          }
        });

        this.mapObject = map;

      });
    });
  },
  methods: {
    getText(type: string) {
      let textsOfType = this.item.texts.filter((text: any) => text.rel == type);
      if(textsOfType.length > 0) {
        return textsOfType[0].value;
      }
      else {
        return '';
      }
    },
    getRating(type: string) {
      let ratingsOfType = this.item.ratings.filter((text: any) => text.type == type);

      if(ratingsOfType.length > 0) {
        return ratingsOfType[0].value;
      }
      else {
        return '';
      }
    },
    getAddress(type: string) {
      let addressesOfType = this.item.addresses.filter((text: any) => text.rel == type);
      if(addressesOfType.length > 0) {
        return addressesOfType[0];
      }
      else {
        return '';
      }
    },
    getGalleryImages() {
      return this.item.media_objects.filter((mediaObject: any) => mediaObject.rel == 'imagegallery');
    },

    getFirstImage() {
      return this.item.media_objects.filter((mediaObject: any) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },

    getYtId() {
      let ytObject = this.item.media_objects.filter((mediaObject: any) => mediaObject.rel == 'video' && mediaObject.url.includes('youtu'));

      if(ytObject.length > 0) {
        return ytObject[0].url.slice(-11);
      }
      else {
        return null;
      }
    },

    getEtappen() {
      let search = 'title:' + this.item.title.split('–')[0].split('-')[0].trim() + '*';

      let etappenArray: Array<any> = this.item.subitems.filter((subitem: any) => subitem.type == 'Relation_zugehörige Tour_Tour');

      this.etappen = etappenArray.sort((a: any, b: any) => {
        if(a.title > b.title) {
          return 1;
        }
        else if(a.title < b.title) {
          return -1
        }
        else {
          return 0;
        }
      });
    },
    isFirstSuitable(index: number): boolean {
      if (index == 0) {
        return true;
      }
      else {
        if (this.item.seasons[index - 1].suitable) {
          return false;
        }
        else {
          return true;
        }
      }
    },
    isLastSuitable(index: number): boolean {
      if (index == this.item.seasons.length - 1) {
        return true;
      }
      else {
        if (this.item.seasons[index + 1].suitable) {
          return false;
        }
        else {
          return true;
        }
      }
    },
    minutesToHours(minutes: number): string {

      minutes = minutes / 60;
      let hours = Math.floor(minutes / 60);
      let restMinutes = minutes % 60;

      return ((hours ? hours : '0') + ':' + restMinutes + 'h');
    },
    downloadGpx() {
      var link = document.createElement("a");
      link.download = 'route.gpx';
      link.href = SERVICES.api.getGpxUrl(this.id);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    checkSeasons() {
      let returnValue = false;
      this.item.seasons.forEach((season: any) => {
        if(season.suitable) {
          returnValue = true;
        }
      });
      return returnValue;
    },
    allYear() {
      let returnValue = true;
      this.item.seasons.forEach((season: any) => {
        if(!season.suitable) {
          returnValue = false;
        }
      });

      return returnValue;
    },
    hoverElevation(event: any) {
      this.elevationDisplay = true;
      this.elevationPosition = event.offsetX;

      console.log(event);

      let coordinatesLength = this.mapData.data.features[0].geometry.coordinates.length;
      let index = Math.max(Math.round(event.offsetX / event.srcElement.clientWidth * coordinatesLength), 0);
      let item = this.mapData.data.features[0].geometry.coordinates[index];

      let elevationFeature = new Feature({
        geometry: new Point(fromLonLat(item, 'EPSG:2056')),
        name: item.title,
        id: index
      });

      const fill = new Fill({
        color: 'rgba(255,255,255,0)',
      });
      const stroke = new Stroke({
        color: '#C8481D',
        width: 3,
      });

      const elevationStyle = new Style({
        image: new Circle({
          fill: fill,
          stroke: stroke,
          radius: 10,
        }),
        fill: fill,
        stroke: stroke,
      });

      elevationFeature.setStyle(elevationStyle);

      let features = [elevationFeature];

      if(this.elevationSource) {
        this.elevationSource.clear();
        this.elevationSource.addFeature(elevationFeature);
      }
      else {
        this.elevationSource = new VectorSource({
          features: features
        });
      }

      if(!this.elevationLayer) {
        this.elevationLayer = new VectorLayer({
          source: this.elevationSource
        });

        this.mapObject.addLayer(this.elevationLayer);
      }


      this.$forceUpdate();
    },
    leaveHoverElevation() {
      this.elevationDisplay = false;
    },
    getAuthor() {
      let authors = this.item.addresses.filter((text: any) => text.rel == 'organisation');
      if(authors.length > 0) {
        return authors[0];
      }
      else {
        return null;
      }
    },
    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
