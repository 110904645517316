




























import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { apiService } from '../services/api.service';
import vueSlickCarousel from 'vue-slick-carousel';
import { urlService } from '../services/url.service';
import { BASEURL } from '@libTs/cms.globals';
import { TranslationService } from '../services/translation.service';

const SERVICES = VServices({ api: apiService, url: urlService, translation: TranslationService });

export default Vue.extend({
  name: 'apiBlock',
  props: {
    data: Array
  },
  components: {
    vueSlickCarousel,
  },
  data() {
    return {
      url: SERVICES.url,
      itemsShowing: [],
      types: [],
      filters: [],
      show: 8,
      baseUrl: BASEURL,
      itemsBefore: 0,
    };
  },
  created() {
    this.items = this.data;

    this.itemsShowing = this.shuffleArray(this.items);

    this.items.forEach((element: any) => {
      if(element.type && !this.types.includes(element.type)) {
        this.types.push(element.type);
      }
    });

    this.types.sort((a: any, b: any) => {
        var nameA = this.t(a).toUpperCase(); // Groß-/Kleinschreibung ignorieren
        var nameB = this.t(b).toUpperCase(); // Groß-/Kleinschreibung ignorieren
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // Namen müssen gleich sein
        return 0;
    });
  },
  methods: {
    toggleFilter(type: string) {
      this.itemsBefore = this.itemsShowing.length;

      let index = this.filters.indexOf(type);

      if(index >= 0) {
        this.filters.splice(index, 1);
      }
      else {
        this.filters.push(type);
      }

      if(this.filters.length > 0) {
        this.itemsShowing = this.shuffleArray(this.items.filter((element: any) => this.filters.includes(element.type)));
      }
      else {
        this.itemsShowing = this.shuffleArray(this.items);
      }

      this.show = 8;
    },
    shuffleArray(array: Array<any>): Array<any> {
      let returnArray = array;
      for (let i = returnArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = returnArray[i];
        returnArray[i] = returnArray[j];
        returnArray[j] = temp;
      }

      return returnArray;
    },
    getNumberOfType(type: string): number {
      return this.items.filter((element: any) => element.type == type).length;
    },

    getFirstImage(item: any) {
      return item.media_objects.filter((mediaObject: any) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
