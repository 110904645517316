<template>
  <div class="map-container">
    <div class="row-container">
      <div class="row-list">
        <div v-for="(item, index) in data" class="item" :key="index">
          <div class="list-image" v-bind:style="'background-image:url(http://img.ecmaps.de/remote/.jpg?width=600&url=' + (getFirstImage(item) ? getFirstImage(item).url : '') + ')'"></div>
          <div class="list-content">
            <div class="cuprum-f3">{{ item.title }}</div>
            <div class="nib-f3">{{ item.type }}</div>
            <div class="mb-4">{{ getText(item, 'teaser') }}</div>
            <a class="map-button" :href="url.getDetailUrl(item.title.replace('\'', ''), item.type, item.id)">Link <i class="fa fa-arrow-right font-light"></i></a>
          </div>
        </div>
      </div>
      <div class="row-map">
        <div id="custom-swiss-map" class="mapContainer"></div>
        <div id="popup-container"></div>
        <div id="popup-closer" class="close-icon"><i class="fas fa-times"></i></div>

        <div v-for="(item, index) in data" :id="'content' + index" v-show="activePopup == index" :key="index">
          <div class="bubble-image" v-bind:style="'background-image:url(http://img.ecmaps.de/remote/.jpg?width=600&url=' + (getFirstImage(item) ? getFirstImage(item).url : '') + ')'"></div>
          <div class="bubble-content">
            <div class="cuprum-f3">{{ item.title }}</div>
            <div class="nib-f3">{{ item.type }}</div>
            <div class="mb-4 text-f5 popup-text">{{ getText(item, 'teaser') }}</div>
            <a class="text-f5 map-button" :href="url.getDetailUrl(item.title.replace('\'', ''), item.type, item.id)">Link <i class="fa fa-arrow-right font-light"></i></a>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { VServices } from '@libTs/vue-base';
import Vue from "vue";
import Popup from '../popup.ts';
import { apiService } from '../services/api.service';
import { urlService } from '../services/url.service';
import vueSlickCarousel from 'vue-slick-carousel';

import proj4 from "proj4";
import { Map, View, Feature, Overlay } from "ol";
import { Tile as TileLayer, Vector as VectorLayer  } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { XYZ, WMTS } from "ol/source";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import { defaults as defaultsInteractions } from "ol/interaction";
import { register } from "ol/proj/proj4";
import { TileWMS } from "ol/source";
import { Point } from "ol/geom";
import { fromLonLat, transform } from "ol/proj";
import { Extent } from "ol/extent";
import { Style, Icon } from "ol/style";
import TileGrid from "ol/tilegrid/TileGrid";

const SERVICES = VServices({ api: apiService, url: urlService });

export default Vue.extend({
  name: "CustomSwissMap",
  props: {
    data: Array,
  },
  components: {
    vueSlickCarousel,
  },
  data: function () {
    return {
      myLatLng: [ 46.93038961832949, 8.597718768318275 ],
      map: null,
      activePopup: null,
      url: SERVICES.url,
    };
  },
  methods: {
    getFirstImage(item) {
      return item.media_objects.filter((mediaObject) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },
    getText(item, type) {
      let textsOfType = item.texts.filter((text) => text.rel == type && text.type == "text/plain");

      console.log(item, type, textsOfType);

      if(textsOfType.length <= 0) {

        textsOfType = item.texts.filter((text) => text.rel == type);
      }

      if(textsOfType.length > 0) {
        return textsOfType[0].value;
      }
      else {
        return '';
      }
    },
  },
  mounted() {
    console.log('data', this.data);

    this.data = this.data.sort((a, b) => {
      if(a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      if(a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    this.$forceUpdate();

    SERVICES.api.getData(this.config).then((response) => {

      this.$nextTick(() => {
        // adding Swiss projections to proj4 (proj string comming from https://epsg.io/)
        proj4.defs(
          "EPSG:2056",
          "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs"
        );
        // proj4.defs(
        //   "EPSG:21781",
        //   "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs"
        // );
        register(proj4);


        const layers = [
          {
            url: 'ch.swisstopo.pixelkarte-farbe-pk200.noscale',
            maxZoom: 14,
            minZoom: 1,
          },
          {
            url: 'ch.swisstopo.pixelkarte-farbe',
            maxZoom: 19,
            minZoom: 14,
          }
        ]


        let onlyLayers = [];

        layers.forEach((layer) => {
          let newlayer = new TileLayer({
            source: new TileWMS({
              url: 'https://wms.geo.admin.ch/',
              crossOrigin: 'anonymous',
              params: {
                'LAYERS': layer.url,
                'FORMAT': 'image/jpeg',
                'TILED': true,
                'VERSION': '1.1.1'
              },
              serverType: 'mapserver',
            }),
            minZoom: layer.minZoom,
            maxZoom: layer.maxZoom,
          });

          onlyLayers.push(newlayer);
        });

        let startZoom = 15;
        let maxZoom = 19;
        let minZoom = 10;

        const view = new View({
          projection: "EPSG:2056",
          center: fromLonLat(this.myLatLng, 'EPSG:2056'),
          zoom: startZoom,
          maxZoom: maxZoom,
          minZoom: minZoom,
          extent: [2623219.221963299, 1153464.0175551437, 2749636.822550499, 1250527.8117530728],
        });

        let startLayer = layers[0].layer;

        var interactions = defaultsInteractions({altShiftDragRotate:false, pinchRotate:false});

        const map = new Map({
          target: "custom-swiss-map",
          controls: defaultControls({rotate: false}).extend([
            new ScaleLine({
              units: "metric"
            })
          ]),
          layers: onlyLayers,
          view: view,
          interactions: interactions
        });

        let southPoint = null;
        let northPoint = null;
        let westPoint = null;
        let eastPoint = null;

        this.data.forEach((item) => {
          console.log('item geo', item);

          let geo = JSON.parse(item.geo);

          let latitude = geo.main.latitude;
          let longitude = geo.main.longitude;

          if(longitude < southPoint || southPoint == null) {
            southPoint = longitude;
          }

          if(longitude > northPoint || northPoint == null) {
            northPoint = longitude;
          }

          if(latitude < westPoint || westPoint == null) {
            westPoint = latitude;
          }

          if(latitude > eastPoint || eastPoint == null) {
            eastPoint = latitude;
          }
        });

        southPoint = southPoint - ((northPoint - southPoint) / 10);
        northPoint = northPoint + ((northPoint - southPoint) / 10);
        westPoint = westPoint - ((eastPoint - westPoint) / 10);
        eastPoint = eastPoint + ((eastPoint - westPoint) / 10);

        let fitExtent = [
          fromLonLat([southPoint, westPoint], 'EPSG:2056')[0],
          fromLonLat([southPoint, westPoint], 'EPSG:2056')[1],
          fromLonLat([northPoint, eastPoint], 'EPSG:2056')[0],
          fromLonLat([northPoint, eastPoint], 'EPSG:2056')[1],
        ];


        map.getView().fit(fitExtent);


        let features = [];

        var container = document.getElementById('popup-container');

        this.data.forEach((item, index) => {
          let geo = JSON.parse(item.geo);

          let latitude = geo.main.latitude;
          let longitude = geo.main.longitude;

          let itemLatLng = [longitude, latitude];

          let markerFeature = new Feature({
            geometry: new Point(fromLonLat(itemLatLng, 'EPSG:2056')),
            name: item.title,
            id: index
          });
          const iconStyle = new Style({
            image: new Icon({
              anchor: [0.5, 1],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              src: 'assets/location-dot-solid.png',
              scale: 0.07
            }),
          });

          markerFeature.setStyle(iconStyle);

          features.push(
            markerFeature
          );

        });

        var overlay = new Overlay({
          element: container,
          autoPan: true,
          autoPanAnimation: {
              duration: 250
          }
        });

        map.addOverlay(overlay);


        map.on('click', function (evt) {
          var feature = map.forEachFeatureAtPixel(evt.pixel, function (feat, layer) {
              return feat;
          });

          if (feature) {
              var coordinate = evt.coordinate;

              container.innerHTML = document.getElementById('content' + feature.get('id')).innerHTML;

              const closer = document.getElementById('popup-closer');
              const closerClone = closer.cloneNode(true);

              closerClone.onclick = function () {
                overlay.setPosition(undefined);
                closer.blur();
                return false;
              };

              container.appendChild(closerClone);

              overlay.setPosition(coordinate);
          }
          else {
            overlay.setPosition(undefined);
          }
        });

        map.on("pointermove", function (evt) {
          var hit = this.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
            return true;  
          }); 
          if (hit) {
            this.getTargetElement().style.cursor = 'pointer';
          } else {
            this.getTargetElement().style.cursor = '';
          }
        });

        var featuresLayer = new VectorLayer({
            source: new VectorSource({
                features: features
            })
        });

        map.addLayer(featuresLayer);

      });
    });
  },
});
</script>
