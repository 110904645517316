var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "\n    grid grid-cols-3\n    m:grid-cols-2\n    s:grid-cols-1\n    justify-evenly\n    items-center\n    gap-x-4 gap-y-8\n  "
    },
    [
      _vm._l(_vm.webcams, function(webcam, key) {
        return _c(
          "a",
          {
            key: key,
            staticClass: "relative block h-full",
            attrs: { href: webcam.url, target: "_blank" }
          },
          [
            _c("div", {
              staticClass: "bg-img__16-9 relative",
              style: "background-image:url(" + webcam.image + ")"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "p-4" }, [
              _c("div", { staticClass: "font-cuprum text-f3 pt-4 uppercase" }, [
                _vm._v("\n        " + _vm._s(webcam.title) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-nib text-f3" }, [
                _vm._v(
                  "\n        " + _vm._s(webcam.location.region) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex items-center pt-8" }, [
                _c("i", { staticClass: "fa-regular fa-location-dot h-max" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "font-display text-f5 font-bold pl-3" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(webcam.location.city) +
                        "\n        "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.roundshot, function(webcam, key) {
        return _c("div", { key: key }, [
          _c("iframe", {
            staticStyle: { width: "100%", height: "650px", border: "none" },
            attrs: {
              id: webcam.id,
              src: webcam.url,
              webkitAllowFullScreen: "",
              mozAllowFullScreen: "",
              allowFullScreen: ""
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-4" }, [
            _c("div", { staticClass: "font-cuprum text-f3 pt-4 uppercase" }, [
              _vm._v("\n        " + _vm._s(webcam.titel) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "font-nib text-f3" }, [
              _vm._v("\n        " + _vm._s(webcam.kanton) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex items-center pt-8" }, [
              _c("i", { staticClass: "fa-regular fa-location-dot h-max" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "font-display text-f5 font-bold pl-3" },
                [_vm._v("\n          " + _vm._s(webcam.ort) + "\n        ")]
              )
            ])
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }