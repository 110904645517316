






























































































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { apiService } from '../services/api.service';
import vueSlickCarousel from 'vue-slick-carousel';
import { urlService } from '../services/url.service';
import { SITEURL } from '@libTs/cms.globals';
import { TranslationService } from '../services/translation.service';

const SERVICES = VServices({ api: apiService, url: urlService, translation: TranslationService });

export default Vue.extend({
  name: 'detailPage',
  props: {
    data: Object
  },
  components: {
    vueSlickCarousel,
  },
  data() {
    return {
      item: this.data,
      url: SERVICES.url,
      siteUrl: SITEURL,
      currentUrl: ''
    };
  },
  created() {

    this.currentUrl = window.location;

    setTimeout(() => {
      let links = $('.webkit-content').find('a');
      links.each(index => {
        if(!links[index].href.includes(this.siteUrl)) {
          links[index].target = '_blank';
        }
      });
    }, 10);
  },
  methods: {
    getText(type: string) {
      let textsOfType = this.item.texts.filter((text: any) => text.rel == type && text.type == "text/html");

      if(textsOfType.length <= 0) {

        textsOfType = this.item.texts.filter((text: any) => text.rel == type);
      }

      if(textsOfType.length > 0) {
        return textsOfType[0].value;
      }
      else {
        return '';
      }
    },
    getAddress(type: string) {
      let addressesOfType = this.item.addresses.filter((text: any) => text.rel == type);
      if(addressesOfType.length > 0) {
        return addressesOfType[0];
      }
      else {
        return '';
      }
    },
    getRating(type: string) {
      let ratingsOfType = this.item.ratings.filter((text: any) => text.type == type);

      if(ratingsOfType.length > 0) {
        return ratingsOfType[0].value;
      }
      else {
        return '';
      }
    },
    getGalleryImages() {
      return this.item.media_objects.filter((mediaObject: any) => mediaObject.rel == 'imagegallery');
    },

    getFirstImage() {
      return this.item.media_objects.filter((mediaObject: any) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },

    getCategoryUrl(category: string) {
      let urlArray: any = {
        'POI': 'erlebnisse',
        'Event': 'erlebnisse/events',
        'Tour': 'erlebnisse/wege',
        'Gastro': 'essen-schlafen',
        'Hotel': 'essen-schlafen',
      };

      let categoryUrl = this.siteUrl + urlArray[category];

      return categoryUrl;
    },

    getYtId() {
      let ytObject = this.item.media_objects.filter((mediaObject: any) => mediaObject.rel == 'video' && mediaObject.url.includes('youtu'));

      if(ytObject.length > 0) {
        return ytObject[0].url.slice(-11);
      }
      else {
        return null;
      }
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
