import { VBaseService, VServices } from "@libTs/vue-base";
import { SITEURL } from '@libTs/cms.globals';


export class urlService extends VBaseService {
  public getDetailUrl( title: string, type: string, id: string ) {
    const slug = encodeURI(title.replace(/[^\w\s]/gi, '').replaceAll(' ', '-').toLowerCase() + '-' + id);

    return `${SITEURL}${type}/${slug}`;
  }
}