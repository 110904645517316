var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "\n    grid\n    grid-cols-[max-content_1fr_max-content]\n    items-center\n    gap-5\n    s:gap-2\n  "
    },
    [
      _c("img", {
        staticClass: "w-10",
        attrs: { src: _vm.weather.icon, alt: "wetter icon" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "s:text-center" }, [_vm._v(_vm._s(_vm.city))]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.weather.temp))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }