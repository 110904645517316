






















import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { apiService } from '../services/api.service';
import vueSlickCarousel from 'vue-slick-carousel';
import { urlService } from '../services/url.service';

const SERVICES = VServices({ api: apiService, url: urlService });

export default Vue.extend({
  name: 'gridBlock',
  props: {
    data: Object,
  },
  components: {
    vueSlickCarousel,
  },
  data() {
    return {
      url: SERVICES.url,
    };
  },
  created() {
    this.items = this.data;
  },
  methods: {
    getFirstImage(item: any) {
      return item.media_objects.filter((mediaObject: any) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },
  },
});
