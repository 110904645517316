import { BASEURL } from '@libTs/cms.globals';
import { VBaseService, VServices } from '@libTs/vue-base';
import { apiService } from '../services/api.service';
import { urlService } from '../services/url.service';
import { TranslationService } from '../services/translation.service';

import axios from 'axios';
import Fuse from 'fuse.js';
import { resolve } from 'path';


const SERVICES = VServices( {
  api: apiService,
  url: urlService,
  translation: TranslationService
} );

export class SearchService extends VBaseService {
  public itemsShowing: Array<any> = [];
  public apiDataPromise = new Promise( ( resolve, reject ) => { } );
  public loading = false;

  public searchEntries( craftValues: any, entiresToSearch: Array<any>, fuseConfig: any, siteId: string, searchString: string ) {
    this.loading = true;
    
    setTimeout( () => {
      this.loading = false;
    }, 500 );

    // filter out only the values that we need
    const filteredValues = craftValues.filter( ( e ) => {
      return e.siteId == siteId && e.keywords != '';
    } );

    const searchOptions = {
      ...fuseConfig,
      keys: [
        {
          name: 'keywords',
        },
      ],
    };

    const search = new Fuse( filteredValues, searchOptions );
    const searchResult = search.search( searchString );
    const mappedResult = searchResult.map( ( el ) => el.item.elementId );
    const uniqueResult = mappedResult.filter( ( el, index ) => {
      return mappedResult.indexOf( el ) == index;
    } );

    return entiresToSearch.filter( el => uniqueResult.includes( String( el.id ) ) );
  }

  public searchApi( fuseConfig: any, searchString: string ): Promise<any> {
    return new Promise( ( resolve, reject ) => {

      this.apiDataPromise.then( ( res ) => {
        const data: Array<any> = res.data.items;

        const searchOptions = {
          ...fuseConfig,
          keys: [
            {
              name: 'title',
            },
            {
              name: 'texts.value',
            },
            {
              name: 'type',
            },
            {
              name: 'city',
            },
            {
              name: 'zip',
            },
            {
              name: 'categories',
            },
          ],
        };

        const apiSearch = new Fuse( data, searchOptions );
        const searchResult = apiSearch.search( searchString );
        const cleanResult = searchResult.map( ( e ) => e.item );

        const mappedData = cleanResult.map( ( e ) => ( {
          url: SERVICES.url.getDetailUrl( e.title.replace('\'', ''), e.type, e.id ),
          title: e.title,
          type: SERVICES.translation.t(e.type),
        } ) );

        resolve( mappedData );

      } ).catch( err => {
        reject( err );
      } );
    } );
  }

  public autocompleteSearchEntries( craftValues: any, entiresToSearch: Array<any>, fuseConfig: any, siteId: string, searchString: string ) {
    // filter out only the values that we need
    const filteredValues = craftValues.filter( ( e ) => {
      return e.siteId == siteId && e.keywords != '';
    } );

    let searchObject: any = {};

    for ( const key in filteredValues ) {
      const el = filteredValues[ key ];

      if ( !searchObject[ el.elementId ] ) {
        searchObject[ el.elementId ] = {};
        searchObject[ el.elementId ][ 'id' ] = el.elementId;
      }
      searchObject[ el.elementId ][ el.attribute ] = String( el.keywords );
    }

    const searchArray: Array<any> = [];
    for ( const key in searchObject ) {
      if ( Object.prototype.hasOwnProperty.call( searchObject, key ) ) {
        const element = searchObject[ key ];
        searchArray.push( element );
      }
    }

    const searchOptions = {
      ...fuseConfig,
      keys: [
        {
          name: 'field',
          weight: 0.2
        },
        {
          name: 'title',
          weight: 0.9
        }
      ],
    };

    const search = new Fuse( searchArray, searchOptions );
    const searchResult = search.search( searchString );
    const mappedResult = searchResult.map( ( el ) => el.item.id );

    return entiresToSearch.filter( el => mappedResult.includes( String( el.id ) ) );
  }

  public getApiData() {
    this.apiDataPromise = SERVICES.api.getData( { limit: '9999' } );
    return this.apiDataPromise;
  }
}

