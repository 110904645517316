





























import Vue, { VNode } from "vue";

export default Vue.extend({
  name: 'AkkordeonSlot',
  props: {
    heading: String,
    open: Boolean
  },

  data() {
    return {
      show: false,
    };
  },
  mounted: function () {
    this.show = this.open;

    setTimeout(() => {
      let height = this.$refs.akkordeonText.clientHeight;
      if (this.show) {
        this.$refs.akkordeonContainer.style.height = height + "px";
      } else {
        this.$refs.akkordeonContainer.style.height = 0;
      }
    }, 500);
  },
  updated: function () {
    let height = this.$refs.akkordeonText.clientHeight;
    if (this.show) {
      this.$refs.akkordeonContainer.style.height = height + "px";
    } else {
      this.$refs.akkordeonContainer.style.height = 0;
    }
  },
});
