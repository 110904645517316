var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.getFirstImage()
      ? _c(
          "div",
          {
            staticClass:
              "webkit-image-banner-container first-block relative slide-in-animation"
          },
          [
            _c(
              "div",
              {
                staticClass: "webkit-image-banner relative",
                style:
                  "background-image:url(http://img.ecmaps.de/remote/.jpg?width=1920&url=" +
                  _vm.getFirstImage().url +
                  ")"
              },
              [_c("div", { staticClass: "banner-filter-detail" })]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "webkit-container" }, [
      _c(
        "div",
        { staticClass: "webkit-content" },
        [
          _c("div", { staticClass: "breadcrumbs" }, [
            _c("a", { attrs: { href: _vm.siteUrl } }, [
              _vm._v("Wiege der Schweiz")
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "fas fa-chevron-right" }),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.getCategoryUrl(_vm.item.type) } }, [
              _vm._v(_vm._s(_vm.t(_vm.item.type)))
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "fas fa-chevron-right" }),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.currentUrl } }, [
              _c("span", { staticClass: "text-color4" }, [
                _vm._v(_vm._s(_vm.item.title))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "nib-f2" }, [_vm._v("Geschichten")]),
          _vm._v(" "),
          _c(
            "h1",
            {
              staticClass:
                "cuprum-f1 s:cuprum-f1-s mb-16 uppercase break-word-hyphen"
            },
            [_vm._v(_vm._s(_vm.item.title))]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", {
            staticClass: "text-f3 mb-8 detail-text font-bold",
            domProps: { innerHTML: _vm._s(_vm.getText("teaser")) }
          }),
          _vm._v(" "),
          _vm.getText("details")
            ? _c("akkordeon-slot", { attrs: { heading: "Geschichte" } }, [
                _vm.getText("details")
                  ? _c("div", {
                      staticClass: "text-f4 mb-8 detail-text",
                      domProps: { innerHTML: _vm._s(_vm.getText("details")) }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.getText("AletschWichtigeMitteilung")
            ? _c("akkordeon-slot", { attrs: { heading: "Landwirtschaft" } }, [
                _vm.getText("AletschWichtigeMitteilung")
                  ? _c("div", {
                      staticClass: "text-f4 mb-8 detail-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getText("AletschWichtigeMitteilung")
                        )
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.getGalleryImages().length > 0
            ? _c(
                "vue-slick-carousel",
                { staticClass: "mt-8", attrs: { dots: true, arrows: false } },
                _vm._l(_vm.getGalleryImages(), function(slide, imageIndex) {
                  return _c("div", { key: imageIndex }, [
                    _c("div", {
                      staticClass: "pt-16/9 bg-img",
                      style: {
                        backgroundImage:
                          "url('http://img.ecmaps.de/remote/.jpg?width=1500&url=" +
                          slide.url +
                          "')"
                      }
                    })
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getYtId()
            ? _c("div", { staticClass: "relative pt-16/9" }, [
                _c("iframe", {
                  staticClass: "absolute top-0 bottom-0 w-full h-full",
                  attrs: {
                    "data-ytid": _vm.getYtId(),
                    src:
                      "https://www.youtube.com/embed/" +
                      _vm.getYtId() +
                      "?rel=0&modestbranding=1&showinfo=0&controls=0&vq=large",
                    frameborder: "0"
                  }
                })
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "sidescroll" }, [
        _c("div", { staticClass: "scroll" }, [
          _c("div", { staticClass: "scroll-content" }, [
            _c("div", { staticClass: "nib-f2 scroll-title" }, [
              _vm._v("Angebot")
            ]),
            _vm._v(" "),
            _vm.getDocuments().length > 0
              ? _c(
                  "div",
                  { staticClass: "cuprum-f2 text-color4 uppercase mb-4" },
                  [_vm._v("Unterlagen Downloaden")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getText("PRICE_INFO")
              ? _c("div", {
                  staticClass: "mb-6 text-f4",
                  domProps: { innerHTML: _vm._s(_vm.getText("PRICE_INFO")) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.getDocuments().length > 0
              ? _c("div", { staticClass: "text-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "center-button",
                      on: {
                        click: function($event) {
                          return _vm.downloadFiles()
                        }
                      }
                    },
                    [_vm._v("Download")]
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-start-3 col-end-23 m:col-start-2 m:col-span-21 mt-12"
        },
        [
          _c("geschichten-map", {
            attrs: { config: { q: "keyword:WdS_Geschichten", limit: 999 } }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addthis-container" }, [
      _c("div", { staticClass: "sharethis-inline-share-buttons" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }