



















































































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { apiService } from '../services/api.service';
import vueSlickCarousel from 'vue-slick-carousel';
import { urlService } from '../services/url.service';
import { BASEURL } from '@libTs/cms.globals';
import { TranslationService } from '../services/translation.service';
import { parse } from 'path';
import { isMainThread } from 'worker_threads';

const SERVICES = VServices({ api: apiService, url: urlService, translation: TranslationService });

export default Vue.extend({
  name: 'apiEventFilterBlock',
  props: {
    data: Array,
    kategorie: String
  },
  components: {
    vueSlickCarousel,
  },
  data() {
    return {
      url: SERVICES.url,
      itemsShowing: [],
      filters: {
        "categories": [],
        "city": [],
      },
      activeFilters: {
        "categories": [],
        "city": [],
      },
      show: 8,
      baseUrl: BASEURL,
      itemsBefore: 0,
      openedFilter: null,
      anfangdatum: null,
      enddatum: null,
    };
  },
  created() {
    this.items = this.data;

    this.itemsShowing = this.shuffleArray(this.items);

    this.items.forEach((element: any) => {

      for(const [key, value] of Object.entries(this.filters)) {

        if(element[key]) {
          let parsedArray = element[key];

          if(typeof parsedArray == "string" && parsedArray.includes("[")) {
            parsedArray = JSON.parse(parsedArray);
          }

          if(typeof parsedArray == "string") {
            if(!value.includes(parsedArray)) {
              value.push(parsedArray);
            }
          } 
          else {
            parsedArray.forEach((filterValue: any) => {
              if(!value.includes(filterValue)) {
                value.push(filterValue);
              }
            });
          }

        }
      }
    });

    for(const [key, value] of Object.entries(this.filters)) {
      value.sort((a: string, b: string) => {
        var nameA = a.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        var nameB = b.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // Namen müssen gleich sein
        return 0; 
      });
    }
  },
  mounted() {
    document.addEventListener('click', this.closeFilter);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeFilter);
  },
  methods: {
    toggleFilter(type: string) {
      this.itemsBefore = this.itemsShowing.length;

      let index = this.filters.indexOf(type);

      if(index >= 0) {
        this.filters.splice(index, 1);
      }
      else {
        this.filters.push(type);
      }

      if(this.filters.length > 0) {
        this.itemsShowing = this.shuffleArray(this.items.filter((element: any) => this.filters.includes(element.type)));
      }
      else {
        this.itemsShowing = this.shuffleArray(this.items);
      }

      this.show = 8;
    },
    shuffleArray(array: Array<any>): Array<any> {
      let returnArray = array;

      return returnArray.sort((a: any, b: any) => {
        let aDate = Date.parse(this.getNextDate(a).start);
        let bDate = Date.parse(this.getNextDate(b).start);

        if (aDate == bDate) {
          return 0;
        }
        return (aDate < bDate) ? -1 : 1;
      });
    },
    getNumberOfType(type: string): number {
      return this.items.filter((element: any) => element.type == type).length;
    },

    getFirstImage(item: any) {
      return item.media_objects.filter((mediaObject: any) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },

    toggleList(name: string) {
      if(this.openedFilter == name) {
        this.openedFilter = null;
      }
      else {
        this.openedFilter = name;
      }
    },

    setFilter(name: string = '', value: string = '') {
      if(name && value) {
        if(this.activeFilters[name].includes(value)) {
          let index = this.activeFilters[name].indexOf(value);
          if (index !== -1) {
            this.activeFilters[name].splice(index, 1);
          }
        } else {
          this.activeFilters[name].push(value);
        }
      }

      let filteredItems = this.items;

      for(const [key, value] of Object.entries(this.activeFilters)) {

        filteredItems = filteredItems.filter((item: any) => {
          if(value.length > 0) {
            let hasValue = false;

            value.forEach((activeFilter: string) => {
              if(item[key].includes(activeFilter)) {
                hasValue = true;
              }
            });

            return hasValue;
          } 
          else {
            return true
          }
        });
      }

      if(this.anfangdatum && this.enddatum) {
        filteredItems = filteredItems.filter((item: any) => {
          let isMatching = false;

          if (item.timeIntervals.length <= 1) {
            if(item.timeIntervals[0].weekdays.length > 0) {
              // logic for checking if the date is in the interval
              item.timeIntervals[0].weekdays.forEach((weekday: any) => {
                let parsedDate = Date.parse(this.getNextWeekday(weekday, new Date(Date.parse(this.anfangdatum))));
                if(parsedDate > Date.parse(this.anfangdatum) && parsedDate < Date.parse(this.enddatum)) {
                  isMatching = true;
                }
              });
            }
            else {
              let parsedDate = Date.parse(item.timeIntervals[0].start);
              if(parsedDate > Date.parse(this.anfangdatum) && parsedDate < Date.parse(this.enddatum)) {
                isMatching = true;
              }
            }
          }
          else {
            item.timeIntervals.forEach((timeInterval: any) => {
              let parsedDate = Date.parse(timeInterval.start);

              if(parsedDate > Date.parse(this.anfangdatum) && parsedDate < Date.parse(this.enddatum)) {
                isMatching = true;
              }
            });
          }
          
          return isMatching;
        });
      }
      else if(this.anfangdatum) {
        filteredItems = filteredItems.filter((item: any) => {
          let isMatching = false;

          if (item.timeIntervals.length <= 1) {
            if(item.timeIntervals[0].weekdays.length > 0) {
              isMatching = true;
            }
            else {
              if(Date.parse(item.timeIntervals[0].start) > Date.parse(this.anfangdatum)) {
                isMatching = true;
              }
            }
          }
          else {
            item.timeIntervals.forEach((timeInterval: any) => {
              if(Date.parse(timeInterval.start) > Date.parse(this.anfangdatum)) {
                isMatching = true;
              }
            });
          }

          return isMatching;
        });
      }
      else if(this.enddatum) {
        filteredItems = filteredItems.filter((item: any) => {
          let isMatching = false;

          if (item.timeIntervals.length <= 1) {
            if(item.timeIntervals[0].weekdays.length > 0) {
              // logic for checking if the date is in the interval
              item.timeIntervals[0].weekdays.forEach((weekday: any) => {
                if(Date.parse(this.getNextWeekday(weekday)) < Date.parse(this.enddatum)) {
                  isMatching = true;
                }
              });
            }
            else {
              if(Date.parse(item.timeIntervals[0].start) < Date.parse(this.enddatum)) {
                isMatching = true;
              }
            }
          }
          else {
            item.timeIntervals.forEach((timeInterval: any) => {
              if(Date.parse(timeInterval.start) < Date.parse(this.enddatum)) {
                isMatching = true;
              }
            });
          }

          return isMatching;
        });
      }

      this.itemsShowing = this.shuffleArray(filteredItems);
    },

    calculateItems(name: string, value: string) {
      return this.itemsShowing.filter((item: any) => {
        return item[name].includes(value);
      }).length;
    },

    iconClick(name: string) {
      if(name == 'anfangdatum') {
        if(this.anfangdatum != null  && this.openedFilter != name) {
          this.anfangdatum = null;
          this.openedFilter = null;

          this.setFilter()
        }
        else {
          this.toggleList(name);
        }
      }
      else if(name == 'enddatum') {
        if(this.enddatum != null) {
          this.enddatum = null;
          this.openedFilter = null;

          this.setFilter()
        }
        else {
          this.toggleList(name);
        }
      }
      else {
        if(this.activeFilters[name].length > 0 && this.openedFilter != name) {
          this.removeFilters(name);
          this.openedFilter = null;
        } else {;
          this.toggleList(name);
        }
      }
    },

    removeFilters(name: string) {
      this.activeFilters[name] = [];

      let filteredItems = this.items;

      for(const [key, value] of Object.entries(this.activeFilters)) {

        filteredItems = filteredItems.filter((item: any) => {
          if(value.length > 0) {
            let hasValue = false;

            value.forEach((activeFilter: string) => {
              if(item[key].includes(activeFilter)) {
                hasValue = true;
              }
            });

            return hasValue;
          } 
          else {
            return true
          }
        });
      }

      this.itemsShowing = this.shuffleArray(filteredItems);
    },

    getNextDate(item: any) {
      if (item.timeIntervals.length <= 1) {
        if(item.timeIntervals[0].weekdays.length > 0) {
          let weekdayDates: any = [];

          item.timeIntervals[0].weekdays.forEach((weekday: any) => {
            weekdayDates.push(this.getNextWeekday(weekday));
          })

          weekdayDates.sort((a: any, b: any) => {
            if (a == b) {
              return 0;
            }
            return (a < b) ? -1 : 1;
          })

          let currentWeekdayDate = weekdayDates[0];

          return {
            'start': this.formatDate(currentWeekdayDate) + ' ' + this.formatTime(item.timeIntervals[0].start),
            'end': this.formatDate(currentWeekdayDate) + ' ' + this.formatTime(item.timeIntervals[0].end),
          };
        }
        else {
          return item.timeIntervals[0];
        }
      }
      else {
        let firstMatchingDate: any = null;

        item.timeIntervals.forEach((timeInterval: any) => {
          if(Date.parse(timeInterval.start) > Date.now()) {
            if(firstMatchingDate == null) {
              firstMatchingDate = timeInterval;
            }
          }
        });
        if(firstMatchingDate != null) {
          return firstMatchingDate;
        }
        else {
          return item.timeIntervals[item.timeIntervals.length - 1];
        }
      }
    },

    getNextWeekday(weekday: string, date = new Date()) {
      const weekdays: any = {
        "Monday": 1,
        "Tuesday": 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6,
        "Sunday": 0,
      }

      const dateCopy = new Date(date.getTime());

      const nextWeekday = new Date(
        dateCopy.setDate(
          dateCopy.getDate() + ((7 - dateCopy.getDay() + weekdays[weekday]) % 7 || 7),
        ),
      );

      return nextWeekday;
    },

    getDate(item: any) {
      let date = this.getNextDate(item);
      return date;
    },

    formatDate(date: any) {

      if(typeof date == 'string') {
        let dateString = date;

        date = new Date(Date.parse(date));

        if(date == 'Invalid Date') {
          var pattern = /(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2})/;
          date = new Date(dateString.replace(pattern,'$3-$2-$1T$4:$5'));
        }
      }

      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();

      return [
              (dd>9 ? '' : '0') + dd,
              (mm>9 ? '' : '0') + mm,
              date.getFullYear(),
            ].join('.');
    },

    formatTime(date: any) {

      if(typeof date == 'string') {
        let dateString = date;

        date = new Date(Date.parse(date));

        if(date == 'Invalid Date') {
          var pattern = /(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2})/;
          date = new Date(dateString.replace(pattern,'$3-$2-$1T$4:$5'));
        }
      }

      var hours = date.getHours();
      var minutes = date.getMinutes();

      return [
              (hours>9 ? '' : '0') + hours,
              (minutes>9 ? '' : '0') + minutes
            ].join(':');
    },

    setAnfangdatum(e: any) {
      this.anfangdatum = e.target.value;

      this.setFilter();
    },

    setEnddatum(e: any) {
      this.enddatum = e.target.value;

      this.setFilter();
    },
    closeFilter() {
      this.openedFilter = null;
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
