









































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { BASEURL } from '@libTs/cms.globals';
import { SearchService } from '../services/search.service';
import { TranslationService } from '../services/translation.service';

const SERVICES = VServices({
  search: SearchService,
  translation: TranslationService
});

export default Vue.extend({
  name: 'search-input',
  props: {
    searchString: String,
    searchIndexValues: String,
    siteId: String,
    entries: Array,
  },
  data() {
    return {
      autoCompleteItems: [],
      baseUrl: BASEURL,
      searchTerm: '',
      selectedItem: -1,
      selectLink: '',
    };
  },

  mounted() {
    SERVICES.search.getApiData();
    this.search(this.searchString);

    window.addEventListener('keyup', (key) => {
      const vm = this;
      const autoCompleteItems = $('.autocomplete-container').children();

      function selectItemByIndex(
        index: number,
        autoCompleteItems: JQuery<HTMLElement>,
        vm: any
      ) {
        const item = $(autoCompleteItems[index]);
        if (item) {
          $(autoCompleteItems).removeClass('active-link');
          item.addClass('active-link');
          vm.selectLink = item.attr('href');
        }
      }

      if (key.code === 'ArrowUp') {
        if (this.selectedItem === 0) {
          this.selectedItem = -1;
          this.selectLink = '';
          $(autoCompleteItems).removeClass('active-link');
          return;
        } else {
          this.selectedItem--;
        }
        selectItemByIndex(this.selectedItem, autoCompleteItems, vm);
      }

      if (key.code === 'ArrowDown') {
        if (this.selectedItem === autoCompleteItems.length - 1) {
          this.selectedItem = 0;
        } else {
          this.selectedItem++;
        }

        selectItemByIndex(this.selectedItem, autoCompleteItems, vm);
      }

      if (key.code === 'Enter') {
        if ($('.search-input').is(':focus') && this.selectLink !== '') {

          key.preventDefault();
          window.location.href = this.selectLink;
          return;
        }
      }

      if (key.code === 'Escape') {
        this.autoCompleteItems = [];
        this.selectLink = '';
        this.selectedItem = -1;
      }
    });
  },
  methods: {
    search(searchString: string, event?: Event) {
      this.autoCompleteItems = [];
      if (event) {
        event.preventDefault();
      }
      SERVICES.search.itemsShowing = [];

      const craftValues: Array<any> = JSON.parse(this.searchIndexValues);
      const result = SERVICES.search.searchEntries(
        craftValues,
        this.entries,
        {
          includeScore: true,
          minMatchCharLength: 3,
          threshold: 0.3,
          ignoreLocation: true,
        },
        this.siteId,
        searchString
      );

      SERVICES.search.itemsShowing = [
        ...result,
        ...SERVICES.search.itemsShowing,
      ];

      SERVICES.search
        .searchApi(
          {
            includeScore: true,
            minMatchCharLength: 3,
            threshold: 0.3,
          },
          searchString
        )
        .then((res) => {
          SERVICES.search.itemsShowing = [
            ...SERVICES.search.itemsShowing,
            ...res,
          ];
        });
    },
    autoCompleteSearch() {
      this.autoCompleteItems = [];
      const craftValues: Array<any> = JSON.parse(this.searchIndexValues);

      const result = SERVICES.search.autocompleteSearchEntries(
        craftValues,
        this.entries,
        {
          includeScore: true,
          minMatchCharLength: 3,
          threshold: 0.3,
          ignoreLocation: true,
        },
        this.siteId,
        this.searchTerm
      );

      this.autoCompleteItems = [
        ...result.slice(0, 5),
        ...this.autoCompleteItems,
      ];

      SERVICES.search
        .searchApi(
          {
            includeScore: true,
            minMatchCharLength: 3,
            threshold: 0.2,
          },
          this.searchTerm
        )
        .then((res) => {
          this.autoCompleteItems = [
            ...this.autoCompleteItems,
            ...res.slice(0, 5),
          ];
        });
    },
    autoComplete() {
      const minCharLength = 3;
      const searchTerm: String = this.searchTerm;

      if (searchTerm.length >= minCharLength) {
        this.autoCompleteSearch();
      }
    },
    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
