























































import { BASEURL } from '@libTs/cms.globals';
import { VServices } from '@libTs/vue-base';
import Vue from 'vue';

import { SearchService } from '../services/search.service';
import SearchInput from './search-input.vue';
import { TranslationService } from '../services/translation.service';


const SERVICES = VServices({
  search: SearchService,
  translation: TranslationService
});
export default Vue.extend({
  name: 'search',
  props: {
    searchString: String,
    searchIndexValues: String,
    siteId: String,
    entries: Array,
  },
  data() {
    return {
      search: SERVICES.search,
      show: 8,
      BASEURL: BASEURL,
    };
  },
  methods: {
    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },

  components: {
    SearchInput,
  },
});
