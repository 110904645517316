var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data && _vm.data.length > 0
    ? _c(
        "vue-slick-carousel",
        {
          staticClass: "slider",
          attrs: {
            dots: false,
            arrows: true,
            "slides-to-show": 4,
            swipeToSlide: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          }
        },
        _vm._l(_vm.data, function(item, index) {
          return _c("div", { key: index, staticClass: "slide" }, [
            _c(
              "a",
              {
                staticClass:
                  "pt-3/4 block relative bg-center bg-cover api-block",
                attrs: {
                  href: _vm.url.getDetailUrl(
                    item.title.replace("'", ""),
                    item.type,
                    item.id
                  )
                }
              },
              [
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "cuprum-f2 uppercase break-word-hyphen" },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "nib-f2" }, [
                    _vm._v(_vm._s(_vm.t(item.categories[0])))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "slide-filter" }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "slide-background",
                  style:
                    "background-image:url(http://img.ecmaps.de/remote/.jpg?width=1000&url=" +
                    (_vm.getFirstImage(item)
                      ? _vm.getFirstImage(item).url
                      : _vm.baseUrl + "/assets/default" + item.type + ".jpg") +
                    ")"
                })
              ]
            )
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }