






















import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { apiService } from '../services/api.service';
import vueSlickCarousel from 'vue-slick-carousel';
import { urlService } from '../services/url.service';
import { BASEURL } from '@libTs/cms.globals';
import { TranslationService } from '../services/translation.service';


const SERVICES = VServices({ api: apiService, url: urlService, translation: TranslationService });

export default Vue.extend({
  name: 'highlightBlock',
  props: {
    data: Array,
  },
  components: {
    vueSlickCarousel,
  },
  data() {
    return {
      url: SERVICES.url,
      show: 8,
      itemsBefore: 0,
    };
  },
  created() {
    this.items = this.data;
  },
  methods: {
    getFirstImage(item: any) {
      return item.media_objects.filter((mediaObject: any) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },
    t(str: string) {

      return SERVICES.translation.t(str);
    },
  },
});
