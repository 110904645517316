
























































































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { apiService } from '../services/api.service';
import vueSlickCarousel from 'vue-slick-carousel';
import { urlService } from '../services/url.service';
import { SITEURL } from '@libTs/cms.globals';
import { TranslationService } from '../services/translation.service';
import Akkordeon from './akkordeon.vue';
import AkkordeonSlot from './akkordeon-slot.vue';
import SchulangebotMap from './schulangebot-map.vue'

const SERVICES = VServices({ api: apiService, url: urlService, translation: TranslationService });

export default Vue.extend({
  name: 'schulangebotDetail',
  props: {
    data: Object,
  },
  components: {
    vueSlickCarousel,
    Akkordeon,
    AkkordeonSlot,
    SchulangebotMap
  },
  data() {
    return {
      item: this.data,
      url: SERVICES.url,
      siteUrl: SITEURL,
      currentUrl: '',
      apiService: new apiService(),
    };
  },
  created() {
    console.log('this.data', this.data);

    this.currentUrl = window.location;

    // this.apiService.getSchulangebotPOIs().then((data: any) => {
    //   this.mapData = data.data.items;
    // });

    setTimeout(() => {
      let links = $('.webkit-content').find('a');
      links.each(index => {
        if(!links[index].href.includes(this.siteUrl)) {
          links[index].target = '_blank';
        }
      });
    }, 10);
  },
  methods: {
    getText(type: string) {
      let textsOfType = this.item.texts.filter((text: any) => text.rel == type && text.type == "text/html");

      if(textsOfType.length <= 0) {

        textsOfType = this.item.texts.filter((text: any) => text.rel == type);
      }

      if(textsOfType.length > 0) {
        return textsOfType[0].value;
      }
      else {
        return '';
      }
    },
    getAddress(type: string) {
      let addressesOfType = this.item.addresses.filter((text: any) => text.rel == type);
      if(addressesOfType.length > 0) {
        return addressesOfType[0];
      }
      else {
        return '';
      }
    },
    getRating(type: string) {
      let ratingsOfType = this.item.ratings.filter((text: any) => text.type == type);

      if(ratingsOfType.length > 0) {
        return ratingsOfType[0].value;
      }
      else {
        return '';
      }
    },
    getNumber(type: string) {
      let numbersOfType = this.item.numbers.filter((number: any) => number.type == type);

      if(numbersOfType.length > 0) {
        return numbersOfType[0].value;
      }
      else {
        return '';
      }
    },
    getGalleryImages() {
      return this.item.media_objects.filter((mediaObject: any) => {
        console.log(mediaObject);
        return mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png'
      });
    },

    getFirstImage() {
      return this.item.media_objects.filter((mediaObject: any) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },

    getCategoryUrl(category: string) {
      let urlArray: any = {
        'POI': 'erlebnisse',
        'Event': 'erlebnisse/events',
        'Tour': 'erlebnisse/wege',
        'Gastro': 'essen-schlafen',
        'Hotel': 'essen-schlafen',
        'Package': 'erlebnisse',
      };

      let categoryUrl = this.siteUrl + urlArray[category];

      return categoryUrl;
    },

    downloadFiles() {
      this.item.media_objects.filter((mediaObject: any) => mediaObject.rel == 'document').forEach((doc: any) => {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = doc.url;
            save.target = '_blank';
            var filename = doc.url.substring(doc.url.lastIndexOf('/')+1);
            save.download = doc.value || filename;
              if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                    document.location = save.href; 
        // window event not working here
                }else{
                    var evt = new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': false
                    });
                    save.dispatchEvent(evt);
                    (window.URL || window.webkitURL).revokeObjectURL(save.href);
                }   
        }

        // for IE < 11
        else if ( !! window.ActiveXObject && document.execCommand)     {
            var _window = window.open(doc.url, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, doc.value || doc.url)
            _window.close();
        }
      });

    },

    getYtId() {
      let ytObject = this.item.media_objects.filter((mediaObject: any) => mediaObject.rel == 'video' && mediaObject.url.includes('youtu'));

      if(ytObject.length > 0) {
        return ytObject[0].url.slice(-11);
      }
      else {
        return null;
      }
    },

    getDocuments() {
      return this.item.media_objects.filter((mediaObject: any) => mediaObject.rel == 'document');
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
