var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SearchInput", {
        attrs: {
          "search-string": _vm.searchString,
          "search-index-values": _vm.searchIndexValues,
          "site-id": _vm.siteId,
          entries: _vm.entries
        }
      }),
      _vm._v(" "),
      _vm.search.itemsShowing &&
      _vm.search.itemsShowing.length > 0 &&
      !_vm.search.loading
        ? _c("div", { staticClass: "relative" }, [
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.t("Es wurden") +
                    " " +
                    _vm.search.itemsShowing.length +
                    " " +
                    _vm.t("Einträge gefunden")
                )
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {},
              _vm._l(_vm.search.itemsShowing.slice(0, _vm.show), function(
                item,
                index
              ) {
                return _c("div", { key: index }, [
                  _c("a", { attrs: { href: item.url } }, [
                    _c("div", { staticClass: "my-6" }, [
                      _c("div", { staticClass: "nib-f3 capitalize" }, [
                        _vm._v(_vm._s(item.type))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cuprum-f2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.title) +
                            "\n              "
                        ),
                        _vm._m(0, true)
                      ])
                    ])
                  ])
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-center mt-8" }, [
              _vm.show < _vm.search.itemsShowing.length
                ? _c(
                    "button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function($event) {
                          _vm.show += 8
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.t("Mehr laden")) + "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.search.loading
        ? _c("div", { staticClass: "fake-loading w-full" }, [
            _c("img", {
              staticClass: "w-14 h-14 mx-auto",
              attrs: {
                src: _vm.BASEURL + "assets/loading.svg",
                alt: "loading icon"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.search.itemsShowing.length <= 0 && !_vm.search.loading
        ? _c("div", { staticClass: "my-10" }, [
            _vm._v("\n    " + _vm._s(_vm.t("Keine Elemente gefunden")) + "\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "cuprum-f3" }, [
      _c("i", { staticClass: "fas fa-angle-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }