var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container flex mb-4 gap-2 m:flex-col" },
        [
          _vm._l(_vm.filters, function(value, name, index) {
            return [
              value.length > 0
                ? _c("div", { key: index, staticClass: "relative" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-small relative pr-12 leading-none py-2",
                        class: {
                          "btn-filled-small": _vm.activeFilters[name].length > 0
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.toggleList(name)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              name == "categories" && _vm.kategorie
                                ? _vm.kategorie
                                : _vm.t(name + "_event")
                            ) +
                            "\n\n          "
                        ),
                        _c("i", {
                          staticClass: "far",
                          class: {
                            "fa-chevron-up": name == _vm.openedFilter,
                            "fa-chevron-down":
                              _vm.activeFilters[name].length == 0 &&
                              name != _vm.openedFilter,
                            "fa-times border-l border-color1":
                              _vm.activeFilters[name].length > 0 &&
                              name != _vm.openedFilter
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.iconClick(name)
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    name == _vm.openedFilter
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "rounded-xl z-50 border-color4 border-2 bg-color1 overflow-hidden top-10 absolute"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-left p-4 max-h-96 overflow-y-auto overflow-x-hidden"
                              },
                              _vm._l(value, function(item, iIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: iIndex,
                                    staticClass:
                                      "cursor-pointer hover:text-color4 hover:font-bold py-2",
                                    class: {
                                      "text-color4 font-bold": _vm.activeFilters[
                                        name
                                      ].includes(item)
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.setFilter(name, item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item) +
                                        "\n            "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "relative" }, [
            _c(
              "button",
              {
                staticClass: "btn-small relative pr-12 leading-none py-2",
                class: { "btn-filled-small": _vm.anfangdatum != null },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleList("anfangdatum")
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.t("Anfangsdatum")) + "\n        "
                ),
                _c("i", {
                  staticClass: "far",
                  class: {
                    "fa-chevron-up": "anfangdatum" == _vm.openedFilter,
                    "fa-chevron-down":
                      _vm.anfangdatum == null &&
                      "anfangdatum" != _vm.openedFilter,
                    "fa-times border-l border-color1":
                      _vm.anfangdatum != null &&
                      "anfangdatum" != _vm.openedFilter
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.iconClick("anfangdatum")
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _vm.openedFilter == "anfangdatum"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "rounded-xl z-50 border-color4 border-2 bg-color1 overflow-hidden top-10 absolute",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-left p-4 max-h-96 overflow-y-auto overflow-x-hidden"
                      },
                      [
                        _c("input", {
                          attrs: { type: "date" },
                          domProps: { value: _vm.anfangdatum },
                          on: { change: _vm.setAnfangdatum }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "relative" }, [
            _c(
              "button",
              {
                staticClass: "btn-small relative pr-12 leading-none py-2",
                class: { "btn-filled-small": _vm.enddatum != null },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleList("enddatum")
                  }
                }
              },
              [
                _vm._v("\n        " + _vm._s(_vm.t("Enddatum")) + "\n        "),
                _c("i", {
                  staticClass: "far",
                  class: {
                    "fa-chevron-up": "enddatum" == _vm.openedFilter,
                    "fa-chevron-down":
                      _vm.enddatum == null && "enddatum" != _vm.openedFilter,
                    "fa-times border-l border-color1":
                      _vm.enddatum != null && "enddatum" != _vm.openedFilter
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.iconClick("enddatum")
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _vm.openedFilter == "enddatum"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "rounded-xl z-50 border-color4 border-2 bg-color1 overflow-hidden top-10 absolute",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-left p-4 max-h-96 overflow-y-auto overflow-x-hidden"
                      },
                      [
                        _c("input", {
                          attrs: { type: "date" },
                          domProps: { value: _vm.enddatum },
                          on: { change: _vm.setEnddatum }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm.itemsShowing && _vm.itemsShowing.length > 0
        ? _c(
            "TransitionGroup",
            {
              staticClass:
                "grid grid-cols-4 gap-x-4 gap-y-8 l:grid-cols-3 m:grid-cols-2 s:grid-cols-1 event-grid",
              attrs: { name: "list", tag: "div" }
            },
            _vm._l(_vm.itemsShowing.slice(0, _vm.show), function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "overflow-hidden card-item",
                  style: {
                    "transition-delay": (index - _vm.itemsBefore) * 100 + "ms"
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "slide-link",
                      attrs: {
                        href: _vm.url.getDetailUrl(
                          item.title.replace("'", ""),
                          item.type,
                          item.id
                        )
                      }
                    },
                    [
                      _c("div", { staticClass: "slide-image" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "overflow-hidden w-full h-full absolute top-0 left-0"
                          },
                          [
                            _c("div", {
                              staticClass: "slide-background",
                              style:
                                "background-image:url(http://img.ecmaps.de/remote/.jpg?width=1000&url=" +
                                (_vm.getFirstImage(item)
                                  ? _vm.getFirstImage(item).url
                                  : _vm.baseUrl +
                                    "/assets/default" +
                                    item.type +
                                    ".jpg") +
                                ")"
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "slide-image-date" }, [
                          _vm._v(
                            _vm._s(_vm.formatDate(_vm.getDate(item).start))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "slide-content" }, [
                        _c(
                          "div",
                          { staticClass: "slide-heading break-word-hyphen" },
                          [_vm._v(_vm._s(item.title))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "slide-date" }, [
                          _c("i", { staticClass: "fa fa-calendar" }),
                          _vm._v(" "),
                          !_vm.getDate(item).end ||
                          _vm.getDate(item).start == _vm.getDate(item).end
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(_vm.getDate(item).start)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formatDate(_vm.getDate(item).start) ==
                          _vm.formatDate(_vm.getDate(item).end)
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(_vm.getDate(item).start)
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.formatTime(_vm.getDate(item).start)
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm.formatTime(_vm.getDate(item).end)
                                    )
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formatDate(_vm.getDate(item).start) !=
                          _vm.formatDate(_vm.getDate(item).end)
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(_vm.getDate(item).start)
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.formatTime(_vm.getDate(item).start)
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm.formatDate(_vm.getDate(item).end)
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.formatTime(_vm.getDate(item).end)
                                    )
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show < _vm.itemsShowing.length
        ? _c(
            "div",
            {
              staticClass: "text-center mt-8",
              on: {
                click: function($event) {
                  _vm.itemsBefore = _vm.show
                  _vm.show += 8
                }
              }
            },
            [
              _c("button", { staticClass: "btn" }, [
                _vm._v(_vm._s(_vm.t("Mehr laden")))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }