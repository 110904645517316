



























































import axios from 'axios';
import Vue from 'vue';

export default Vue.extend({
  name: 'webcams',
  props: {
    ids: String,
    roundshot: Array
  },
  components: {},
  data() {
    return {
      webcams: '',
    };
  },
  created() {
    const API_KEY = 'gZUiMSxGsmFP1uywRdwLlRldLBnUoiGX';
    const API_BASE = 'https://api.windy.com/webcams/api/v3';
    const requestUrl = `${API_BASE}/webcams?webcamIds=${this.ids}&lang=de&include=images,urls,location`;

    console.log('roundshot', this.roundshot);

    axios.get(
      requestUrl,
      {
        headers: {
          'Accept': 'application/json',
          'X-Windy-Api-Key': API_KEY
        },
      } 
    ).then((r) => {
      const webcams: Array<any> = r.data.webcams;

      this.webcams = webcams.map((e) => ({
        title:
          e.title == e.location.city
            ? e.title
            : (e.title as string)
                .replace(e.location.city, '')
                .replace(':', '')
                .replace('›', ''),
        image: e.images.current.preview,
        url: e.urls.detail,
        location: e.location,
      }));
    });
  },
});
