var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fade-in-animation" },
    _vm._l(_vm.items, function(item, index) {
      return _c("div", { key: index, staticClass: "py-4 px-12" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.url.getDetailUrl(
                item.title.replace("'", ""),
                item.type,
                item.id
              )
            }
          },
          [
            _c("b", [_vm._v(_vm._s(item.title))]),
            _vm._v(" "),
            _c("div", [_vm._v("Attribute: " + _vm._s(item.categories))]),
            _vm._v(" "),
            _c("div", [_vm._v("Stadt: " + _vm._s(item.city))]),
            _vm._v(" "),
            _c("div", [_vm._v("Firma:" + _vm._s(item.company))]),
            _vm._v(" "),
            item.type == "Gastro"
              ? _c("div", [_vm._v("Kocharten:")])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [_vm._v("Highlight: " + _vm._s(item.highlight))]),
            _vm._v(" "),
            _c("div", [_vm._v("Texte: " + _vm._s(item.texts))]),
            _vm._v(" "),
            _c("div", [_vm._v("Type: " + _vm._s(item.type))])
          ]
        ),
        _vm._v(" "),
        (item.media_objects
        ? item.media_objects.length
        : false)
          ? _c("a", { attrs: { href: _vm.getFirstImage(item).url } }, [
              _vm._v("Vorschau Bild")
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }