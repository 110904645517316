












































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { apiService } from '../services/api.service';
import vueSlickCarousel from 'vue-slick-carousel';
import { urlService } from '../services/url.service';
import { BASEURL } from '@libTs/cms.globals';
import { TranslationService } from '../services/translation.service';
import { parse } from 'path';

const SERVICES = VServices({ api: apiService, url: urlService, translation: TranslationService });

export default Vue.extend({
  name: 'apiFilterBlock',
  props: {
    data: Array,
    kategorie: String
  },
  components: {
    vueSlickCarousel,
  },
  data() {
    return {
      url: SERVICES.url,
      itemsShowing: [],
      filters: {
        "cuisine_types": [],
        "categories": [],
        "features": [],
        "city": [],
      },
      activeFilters: {
        "cuisine_types": [],
        "categories": [],
        "features": [],
        "city": [],
      },
      show: 8,
      baseUrl: BASEURL,
      itemsBefore: 0,
      openedFilter: null
    };
  },
  created() {
    this.items = this.data;

    this.itemsShowing = this.shuffleArray(this.items);

    this.items.forEach((element: any) => {
      console.log(element);

      for(const [key, value] of Object.entries(this.filters)) {

        if(element[key]) {
          let parsedArray = element[key];

          if(typeof parsedArray == "string" && parsedArray.includes("[")) {
            parsedArray = JSON.parse(parsedArray);
          }

          if(typeof parsedArray == "string") {
            if(!value.includes(parsedArray)) {
              value.push(parsedArray);
            }
          } 
          else {
            parsedArray.forEach((filterValue: any) => {
              if(!value.includes(filterValue)) {
                value.push(filterValue);
              }
            });
          }

        }
      }
    });

    for(const [key, value] of Object.entries(this.filters)) {
      value.sort((a: string, b: string) => {
        var nameA = a.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        var nameB = b.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // Namen müssen gleich sein
        return 0; 
      });
    }
  },
  mounted() {
    document.addEventListener('click', this.closeFilter);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeFilter);
  },
  methods: {
    toggleFilter(type: string) {
      this.itemsBefore = this.itemsShowing.length;

      let index = this.filters.indexOf(type);

      if(index >= 0) {
        this.filters.splice(index, 1);
      }
      else {
        this.filters.push(type);
      }

      if(this.filters.length > 0) {
        this.itemsShowing = this.shuffleArray(this.items.filter((element: any) => this.filters.includes(element.type)));
      }
      else {
        this.itemsShowing = this.shuffleArray(this.items);
      }

      this.show = 8;
    },
    shuffleArray(array: Array<any>): Array<any> {
      let returnArray = array;
      for (let i = returnArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = returnArray[i];
        returnArray[i] = returnArray[j];
        returnArray[j] = temp;
      }

      return returnArray;
    },
    getNumberOfType(type: string): number {
      return this.items.filter((element: any) => element.type == type).length;
    },

    getFirstImage(item: any) {
      return item.media_objects.filter((mediaObject: any) => mediaObject.type == 'image/jpeg' || mediaObject.type == 'image/png')[0];
    },

    toggleList(name: string) {
      if(this.openedFilter == name) {
        this.openedFilter = null;
      }
      else {
        this.openedFilter = name;
      }
    },

    setFilter(name: string, value: string) {
      if(this.activeFilters[name].includes(value)) {
        let index = this.activeFilters[name].indexOf(value);
        if (index !== -1) {
          this.activeFilters[name].splice(index, 1);
        }
      } else {
        this.activeFilters[name].push(value);
      }

      let filteredItems = this.items;

      for(const [key, value] of Object.entries(this.activeFilters)) {
        console.log(key, value);

        filteredItems = filteredItems.filter((item: any) => {
          if(value.length > 0) {
            let hasValue = false;

            value.forEach((activeFilter: string) => {
              if(item[key].includes(activeFilter)) {
                hasValue = true;
              }
            });

            return hasValue;
          } 
          else {
            return true
          }
        });
      }

      this.itemsShowing = this.shuffleArray(filteredItems);
    },

    calculateItems(name: string, value: string) {
      return this.itemsShowing.filter((item: any) => {
        return item[name].includes(value);
      }).length;
    },

    iconClick(name: string) {
      if(this.activeFilters[name].length > 0 && this.openedFilter != name) {
        this.removeFilters(name);
        this.openedFilter = null;
      } else {;
        this.toggleList(name);
      }
    },

    removeFilters(name: string) {
      this.activeFilters[name] = [];

      let filteredItems = this.items;

      for(const [key, value] of Object.entries(this.activeFilters)) {
        console.log(key, value);

        filteredItems = filteredItems.filter((item: any) => {
          if(value.length > 0) {
            let hasValue = false;

            value.forEach((activeFilter: string) => {
              if(item[key].includes(activeFilter)) {
                hasValue = true;
              }
            });

            return hasValue;
          } 
          else {
            return true
          }
        });
      }

      this.itemsShowing = this.shuffleArray(filteredItems);
    },
    closeFilter() {
      this.openedFilter = null;
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
