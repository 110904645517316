var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _vm.getFirstImage()
      ? _c(
          "div",
          {
            staticClass:
              "webkit-image-banner-container first-block relative slide-in-animation"
          },
          [
            _c(
              "div",
              {
                staticClass: "webkit-image-banner relative",
                style:
                  "background-image:url(http://img.ecmaps.de/remote/.jpg?width=1920&url=" +
                  _vm.getFirstImage().url +
                  ")"
              },
              [_c("div", { staticClass: "banner-filter-detail" })]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "webkit-container" }, [
      _c(
        "div",
        { staticClass: "webkit-content" },
        [
          _c("div", { staticClass: "breadcrumbs" }, [
            _c("a", { attrs: { href: _vm.siteUrl } }, [
              _vm._v("Wiege der Schweiz")
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "fas fa-chevron-right" }),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.siteUrl + "erlebnisse/wege" } }, [
              _vm._v(_vm._s(_vm.item.type))
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "fas fa-chevron-right" }),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.currentUrl } }, [
              _c("span", { staticClass: "text-color4" }, [
                _vm._v(_vm._s(_vm.item.title))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "nib-f2" }, [
            _vm._v(_vm._s(_vm.item.categories[0]))
          ]),
          _vm._v(" "),
          _c(
            "h1",
            {
              staticClass:
                "cuprum-f1 s:cuprum-f1-s mb-16 uppercase break-word-hyphen"
            },
            [_vm._v(_vm._s(_vm.item.title))]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm.getRating("difficulty") ||
          _vm.getRating("Panorama") ||
          _vm.getRating("Kondition") ||
          _vm.item.elevationMax ||
          _vm.item.elevationMin ||
          _vm.item.seasons ||
          _vm.getText("TourMoreInfos_additionalInformation")
            ? _c(
                "akkordeon-slot",
                { attrs: { heading: _vm.t("Details"), open: true } },
                [
                  _c("div", { staticClass: "text-f4 mb-16" }, [
                    _vm.getRating("difficulty")
                      ? _c("div", { staticClass: "mb-6 grid grid-cols-4" }, [
                          _c(
                            "div",
                            { staticClass: "m:col-span-2 s:col-span-4 s:mb-2" },
                            [_vm._v(_vm._s(_vm.t("Schwierigkeit:")))]
                          ),
                          _vm._v(" "),
                          _vm.getRating("difficulty") < 41
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-span-3 m:col-span-2 s:col-span-4"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-color5 p-2 float-left text-f5 rounded-full px-6"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.t("leicht")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getRating("difficulty") > 41 &&
                          _vm.getRating("difficulty") < 66
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-span-3 m:col-span-2 s:col-span-4"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-color3 text-color1 p-2 float-left text-f5 rounded-full px-6"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.t("mittel")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getRating("difficulty") > 66
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-span-3 m:col-span-2 s:col-span-6"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-color2 text-color1 p-2 float-left rounded-full px-4t"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.t("schwierig")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getRating("Panorama")
                      ? _c("div", { staticClass: "mb-6 grid grid-cols-4" }, [
                          _c(
                            "div",
                            { staticClass: "m:col-span-2 s:col-span-4 s:mb-2" },
                            [_vm._v(_vm._s(_vm.t("Panorama:")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-span-3 m:col-span-2 s:col-span-4"
                            },
                            _vm._l(5, function(index) {
                              return _c("div", {
                                key: index,
                                staticClass:
                                  "inline-block h-4 w-4 rounded-full mr-3 border border-color5",
                                class: {
                                  "bg-color5":
                                    (index + 1) * 10 < _vm.getRating("Panorama")
                                }
                              })
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getRating("Kondition")
                      ? _c("div", { staticClass: "mb-6 grid grid-cols-4" }, [
                          _c(
                            "div",
                            { staticClass: "m:col-span-2 s:col-span-4 s:mb-2" },
                            [_vm._v(_vm._s(_vm.t("Kondition:")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-span-3 m:col-span-2 s:col-span-4"
                            },
                            _vm._l(5, function(index) {
                              return _c("div", {
                                key: index,
                                staticClass:
                                  "inline-block h-4 w-4 rounded-full mr-3 border border-color5",
                                class: {
                                  "bg-color5":
                                    (index + 1) * 10 <
                                    _vm.getRating("Kondition")
                                }
                              })
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.elevationMax
                      ? _c("div", { staticClass: "mb-6 grid grid-cols-4" }, [
                          _c(
                            "div",
                            { staticClass: "m:col-span-2 s:col-span-4 s:mb-2" },
                            [_vm._v(_vm._s(_vm.t("Höchster Punkt:")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-span-3 m:col-span-2 s:col-span-4"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.item.elevationMax +
                                      " " +
                                      _vm.t("m ü. M.")
                                  ) +
                                  " "
                              ),
                              _c("i", { staticClass: "fas fa-arrow-to-top" })
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.elevationMin
                      ? _c("div", { staticClass: "mb-6 grid grid-cols-4" }, [
                          _c(
                            "div",
                            { staticClass: "m:col-span-2 s:col-span-4 s:mb-2" },
                            [_vm._v(_vm._s(_vm.t("Tiefster Punkt:")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-span-3 m:col-span-2 s:col-span-4"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.item.elevationMin +
                                      " " +
                                      _vm.t("m ü. M.")
                                  ) +
                                  " "
                              ),
                              _c("i", { staticClass: "fas fa-arrow-to-bottom" })
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.seasons &&
                    _vm.item.seasons.length > 0 &&
                    _vm.checkSeasons()
                      ? _c("div", { staticClass: "mb-6 grid grid-cols-4" }, [
                          _c(
                            "div",
                            { staticClass: "m:col-span-2 s:col-span-4 s:mb-2" },
                            [_vm._v(_vm._s(_vm.t("Beste Jahreszeit:")) + " ")]
                          ),
                          _vm._v(" "),
                          _vm.allYear()
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-span-3 m:col-span-2 s:col-span-4"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.t("ganzjährig")) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "col-span-3 m:col-span-2 s:col-span-4"
                                },
                                _vm._l(_vm.item.seasons, function(
                                  season,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "float-left px-4 m:px-3 s:px-2 mb-2",
                                      class: {
                                        suitable: season.suitable,
                                        "rounded-l-full": _vm.isFirstSuitable(
                                          index
                                        ),
                                        "rounded-r-full": _vm.isLastSuitable(
                                          index
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.months[index]) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.getText("TourMoreInfos_additionalInformation")
                    ? _c("div", { staticClass: "text-f5 mb-8" }, [
                        _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                          _vm._v(_vm._s(_vm.t("Weitere Infos und Links")))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "detail-text",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getText("TourMoreInfos_additionalInformation")
                            )
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tour-map mb-8" },
                    [
                      _c("div", {
                        staticClass: "mapContainer",
                        attrs: { id: "tour-detail-map" }
                      }),
                      _vm._v(" "),
                      _c("div", { attrs: { id: "popup-container" } }),
                      _vm._v(" "),
                      _vm._l(_vm.offwayTrackpoints, function(item, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            key: index,
                            attrs: { id: "content" + index }
                          },
                          [
                            _c("div", { staticClass: "bubble-content p-6" }, [
                              _c("div", { staticClass: "cuprum-f3" }, [
                                _vm._v(_vm._s(item.title))
                              ])
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "elevation-profile relative" }, [
                    _c("img", {
                      staticClass: "w-full",
                      attrs: {
                        src:
                          "https://static.et4.de/ElevationProfileChart/Go.ashx?Experience=wiege-der-schweiz&globalid=" +
                          _vm.item.global_id +
                          "&width=1280&height=720&AddElevations=true&AddOfftrackWaypoints=false&AddSurfaces=true&AddWaypoints=true&LineColor=90111C"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "elevation-hover-box" }, [
                      _c("div", {
                        staticClass:
                          "elevationHover absolute w-[2px] h-full top-0 bg-color3",
                        style: {
                          left: _vm.elevationPosition + "px"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "absolute w-full h-full",
                        on: {
                          mousemove: _vm.hoverElevation,
                          mouseleave: function($event) {
                            return _vm.leaveHoverElevation()
                          }
                        }
                      })
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getText("teaser") ||
          _vm.getText("details") ||
          _vm.getText("TourMoreInfos_tip")
            ? _c("akkordeon-slot", { attrs: { heading: _vm.t("Die Tour") } }, [
                _vm.getText("teaser")
                  ? _c("div", {
                      staticClass: "text-f4 mb-8 detail-text",
                      domProps: { innerHTML: _vm._s(_vm.getText("teaser")) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.getText("details")
                  ? _c("div", {
                      staticClass: "text-f5 mb-8 detail-text",
                      domProps: { innerHTML: _vm._s(_vm.getText("details")) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.getText("TourMoreInfos_tip")
                  ? _c("div", { staticClass: "text-f5 mb-8 detail-text" }, [
                      _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                        _vm._v(_vm._s(_vm.t("Autorentipp")))
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getText("TourMoreInfos_tip"))
                        }
                      })
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.getText("ROUTE_START") ||
          _vm.getText("ROUTE_ZIEL") ||
          _vm.getText("TourMoreInfos_directions")
            ? _c(
                "akkordeon-slot",
                { attrs: { heading: _vm.t("Wegbeschreibung") } },
                [
                  _vm.getText("ROUTE_START")
                    ? _c("div", { staticClass: "text-f5 mb-8" }, [
                        _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                          _vm._v(_vm._s(_vm.t("Start")))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "detail-text",
                          domProps: {
                            innerHTML: _vm._s(_vm.getText("ROUTE_START"))
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getText("ROUTE_ZIEL")
                    ? _c("div", { staticClass: "text-f5 mb-8" }, [
                        _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                          _vm._v(_vm._s(_vm.t("Ziel")))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "detail-text",
                          domProps: {
                            innerHTML: _vm._s(_vm.getText("ROUTE_ZIEL"))
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getText("TourMoreInfos_directions")
                    ? _c("div", { staticClass: "text-f5 mb-8" }, [
                        _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                          _vm._v(_vm._s(_vm.t("Wegbeschreibung")))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "detail-text",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getText("TourMoreInfos_directions")
                            )
                          }
                        })
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getText("TourMoreInfos_publicTransit") ||
          _vm.getText("TourMoreInfos_gettingThere") ||
          _vm.getText("TourMoreInfos_parking")
            ? _c("akkordeon-slot", { attrs: { heading: _vm.t("Anreise") } }, [
                _vm.getText("TourMoreInfos_publicTransit")
                  ? _c("div", { staticClass: "text-f5 mb-8" }, [
                      _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                        _vm._v(_vm._s(_vm.t("Öffentliche Verkehrsmittel")))
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "detail-text",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getText("TourMoreInfos_publicTransit")
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getText("TourMoreInfos_gettingThere")
                  ? _c("div", { staticClass: "text-f5 mb-8" }, [
                      _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                        _vm._v(_vm._s(_vm.t("Anfahrt")))
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "detail-text",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getText("TourMoreInfos_gettingThere")
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getText("TourMoreInfos_parking")
                  ? _c("div", { staticClass: "text-f5 mb-8" }, [
                      _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                        _vm._v(_vm._s(_vm.t("Parken")))
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "detail-text",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getText("TourMoreInfos_parking")
                          )
                        }
                      })
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.getText("TourMoreInfos_literature") ||
          _vm.getText("TourMoreInfos_maps")
            ? _c("akkordeon-slot", { attrs: { heading: _vm.t("Literatur") } }, [
                _vm.getText("TourMoreInfos_literature")
                  ? _c("div", { staticClass: "text-f5 mb-8" }, [
                      _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                        _vm._v(_vm._s(_vm.t("Buchempfehlungen des Autors")))
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "detail-text",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getText("TourMoreInfos_literature")
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getText("TourMoreInfos_maps")
                  ? _c("div", { staticClass: "text-f5 mb-8" }, [
                      _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                        _vm._v(_vm._s(_vm.t("Kartenempfehlungen des Autors")))
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "detail-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.getText("TourMoreInfos_maps"))
                        }
                      })
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.getText("TourMoreInfos_equipment")
            ? _c(
                "akkordeon-slot",
                { attrs: { heading: _vm.t("Ausrüstung") } },
                [
                  _c("div", { staticClass: "text-f5 mb-8" }, [
                    _c("div", { staticClass: "text-f4 font-bold mb-2" }, [
                      _vm._v(_vm._s(_vm.t("Ausrüstung")))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "detail-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getText("TourMoreInfos_equipment")
                        )
                      }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.etappen && _vm.etappen.length > 0
            ? _c("akkordeon-slot", { attrs: { heading: _vm.t("Etappen") } }, [
                _c(
                  "div",
                  { staticClass: "text-f5 mb-8" },
                  _vm._l(_vm.etappen, function(etappe, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.url.getDetailUrl(
                              etappe.title.replace("'", ""),
                              "Tour",
                              etappe.id
                            )
                          }
                        },
                        [_vm._v(_vm._s(etappe.title))]
                      )
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.getGalleryImages().length > 0
            ? _c(
                "vue-slick-carousel",
                { staticClass: "mt-8", attrs: { dots: true, arrows: false } },
                _vm._l(_vm.getGalleryImages(), function(slide, imageIndex) {
                  return _c("div", { key: imageIndex }, [
                    _c("div", {
                      staticClass: "pt-16/9 bg-img",
                      style: {
                        backgroundImage:
                          "url('http://img.ecmaps.de/remote/.jpg?width=1500&url=" +
                          slide.url +
                          "')"
                      }
                    })
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getYtId()
            ? _c("div", { staticClass: "relative pt-16/9" }, [
                _c("iframe", {
                  staticClass: "absolute top-0 bottom-0 w-full h-full",
                  attrs: {
                    "data-ytid": _vm.getYtId(),
                    src:
                      "https://www.youtube.com/embed/" +
                      _vm.getYtId() +
                      "?rel=0&modestbranding=1&showinfo=0&controls=0&vq=large",
                    frameborder: "0"
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.getAuthor() != null
            ? _c("div", { staticClass: "mt-8" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.t("Verantwortlich für diesen Inhalt:")) +
                    "\n        "
                ),
                _vm.getAuthor().web.length > 0
                  ? _c(
                      "a",
                      {
                        attrs: { href: _vm.getAuthor().web, target: "_blank" }
                      },
                      [_vm._v(_vm._s(_vm.getAuthor().name))]
                    )
                  : _c("span", [_vm._v(_vm._s(_vm.getAuthor().name))])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "sidescroll" }, [
        _c("div", { staticClass: "scroll-tour" }, [
          _c("div", { staticClass: "scroll-content" }, [
            _c("div", { staticClass: "nib-f2 mb-8" }, [
              _vm._v(_vm._s(_vm.t("Touren Überblick")))
            ]),
            _vm._v(" "),
            _vm.getRating("difficulty")
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "cuprum-f3 text-color4 uppercase" },
                    [_vm._v(_vm._s(_vm.t("Schwierigkeit")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-6" }, [
                    _c("div", [
                      _vm.getRating("difficulty") < 41
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-color5 p-2 text-f5 inline-block rounded-full px-6"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.t("leicht")) +
                                    "\n                  "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getRating("difficulty") > 41 &&
                      _vm.getRating("difficulty") < 66
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-color3 text-color1 p-2 text-f5 inline-block rounded-full px-6"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.t("mittel")) +
                                    "\n                  "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getRating("difficulty") > 66
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-color2 text-color1 p-2 inline-block rounded-full px-6"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.t("schwierig")) +
                                    "\n                  "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.length
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "cuprum-f3 text-color4 uppercase" },
                    [_vm._v(_vm._s(_vm.t("Strecke")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-6 text-f4 font-bold" }, [
                    _c("div", [
                      _c("i", { staticClass: "fas fa-route font-normal" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.length / 1000) +
                          "km\n              "
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.duration
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "cuprum-f3 text-color4 uppercase" },
                    [_vm._v(_vm._s(_vm.t("Dauer")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-6 text-f4 font-bold" }, [
                    _c("div", [
                      _c("i", { staticClass: "fas fa-clock font-normal" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.minutesToHours(_vm.item.duration)) +
                          "\n              "
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.totalAscent
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "cuprum-f3 text-color4 uppercase" },
                    [_vm._v(_vm._s(_vm.t("Aufstieg")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-6 text-f4 font-bold" }, [
                    _c("div", [
                      _c("i", { staticClass: "fas fa-arrow-up font-normal" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.totalAscent) +
                          "hm\n              "
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.totalDescent
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "cuprum-f3 text-color4 uppercase" },
                    [_vm._v(_vm._s(_vm.t("Abstieg")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-6 text-f4 font-bold" }, [
                    _c("div", [
                      _c("i", { staticClass: "fas fa-arrow-down font-normal" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.totalDescent) +
                          "hm\n              "
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.elevationMax
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "cuprum-f3 text-color4 uppercase" },
                    [_vm._v(_vm._s(_vm.t("Höchster Punkt")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-6 text-f4 font-bold" }, [
                    _c("div", [
                      _c("i", {
                        staticClass: "fas fa-arrow-to-top font-normal"
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.elevationMax) +
                          " m ü. M.\n              "
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.elevationMin
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "cuprum-f3 text-color4 uppercase" },
                    [_vm._v(_vm._s(_vm.t("Tiefster Punkt")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-6 text-f4 font-bold" }, [
                    _c("div", [
                      _c("i", {
                        staticClass: "fas fa-arrow-to-bottom font-normal"
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.elevationMin) +
                          " m ü. M.\n              "
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.elevationMin
              ? _c("div", [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "btn-filled cursor-pointer inline-block",
                        on: {
                          click: function($event) {
                            return _vm.downloadGpx()
                          }
                        }
                      },
                      [_vm._v("\n                Download GPX\n              ")]
                    )
                  ])
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addthis-container" }, [
      _c("div", { staticClass: "sharethis-inline-share-buttons" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }